/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SECTIONS FLEXIBLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

section {
    padding:  0 0 2rem;

    .section_title {
        font-size: pte(24px, $fz);
        margin-bottom: 4vh;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .section_subtitle {
        color: $col1;
        font-family: $ff1;
        font-size: pte(16px, $fz);
        text-transform: uppercase;
        line-height: 1.5;
    }

    &.separator {
        .sep {
            width: calc(100% - 1rem);
            height: 1px;
            margin: 0 auto;
            background-color: #C0C0C0;
        }
    }

    /* ----------------------------------------------------- */
    /* SOCIAL WALL */
    /* ----------------------------------------------------- */
    &.social_wall {
        padding: 4vh 4vw;
        position: relative;

        .section_title {
            color: $light;
        }

        .pattern {
            &.top-center {
                width: 90%;

                svg {
                    -webkit-transform: translate(0, -35%);
                    -ms-transform: translate(0, -35%);
                    -o-transform: translate(0, -35%);
                    transform: translate(0, -35%);
                    overflow: visible;
                }

                path {
                    fill: $col3;
                }
            }

            &.bottom-left {
                width: 40%;

                path {
                    fill: $col3;
                }
            }
        }

        .container {
            z-index: 10;
        }

        .tabs {
            float: right;
            list-style: none;
            margin: 0;

            .tab {
                list-style: none;
                font-family: $ff1;
                font-size: 0.5em;
                font-weight: bold;
                color: $light;
                text-transform: uppercase;
            }
        }

        .subtitle {
            float: right;
            font-family: $ff1;
            font-size: 0.5em;
            font-weight: bold;
            text-transform: uppercase;
        }

        .socialwall {
            &-list {
                @for $i from 1 through 12 {
                    .col-#{$i} {
                        .socialwall-item {
                            > a {
                                height: calc(92vw / (12 / #{$i} + 1) );
                            }
                        }
                    }
                }
            }

            &-item {
                margin: 2% 0;

                > a {
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                    color: $light;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                .over {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transition: $trans;
                    -moz-transition: $trans;
                    -ms-transition: $trans;
                    -o-transition: $trans;
                    transition: $trans;
                    padding: 1em;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* TITLE SUR LE BANDEAU */
    /* ----------------------------------------------------- */
    &.title {
        padding: 0;

        .col_title {
            background-color: $light;
            box-shadow: 0 2px 4px rgba($dark, .4);
            -webkit-transform: translate(0, -2rem);
            -ms-transform: translate(0, -2rem);
            -o-transform: translate(0, -2rem);
            transform: translate(0, -2rem);

            .title {
                padding: ptr(12px, $fz);
            }
        }
    }

    /* ----------------------------------------------------- */
    /* BANDEAU */
    /* ----------------------------------------------------- */
    &.bandeau {
        position: relative;
        height: 33vh;
        padding: 0;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }

        .container {
            position: relative;
            z-index: 10;
        }

        .bandeau {
            &__image {
                width: 100%;
                height: 100%;
                overflow: hidden;
                
                img {
                    @include objfit();
                }
            }

            &__title {
                @include centerEl();
                z-index: 1000;

                .page__title {
                    color: $light;
                    text-align: center;
                    text-shadow: 0 2px 28px rgba($dark, .4);
                }
            }

            &__tabs {
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: 1021;
            }

            &__slider {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* SINGLE POST THUMBNAIL */
    /* ----------------------------------------------------- */
    &.post_thumbnail {
        margin-top: -16vh;
        position: relative;
        z-index: 3;

        .post_thumbnail_image {
            max-height: 28vh;
            overflow: hidden;

            img {
                @include objfit;
            }

            .slider-container {
                height: 100%;
                padding-bottom: 30px;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* TEXTE IMAGE TEXTE */
    /* ----------------------------------------------------- */
    &.txt_img,
    &.img_txt {
        padding: 2vh 0;

        .txt {
            position: relative;
            order: 1;

            .column {
                &__title {
                    margin-bottom: 2rem;

                    h3 {
                        color: $col3;
                        text-transform: none;
                        font-size: ptr(24px, $fz);
                    }
                }
            }

            .text {
                text-align: justify;
            }

            .pattern {
                width: 60%;
                height: auto;
                overflow: visible;

                svg {
                    overflow: visible;
                    -webkit-transform: translate(0, 15%);
                    -ms-transform: translate(0, 15%);
                    -o-transform: translate(0, 15%);
                    transform: translate(0, 15%);

                    path {
                        fill: rgba($col3, 0.5);
                    }
                }
            }
        }

        .img {
            order: 2;

            img {
                @include objfit;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* TEXTE LARGE */
    /* ----------------------------------------------------- */
    &.txt-large {
        padding: 4vh 0;
    }

    /* ----------------------------------------------------- */
    /* IMAGE SEULE SUR TOUTE LA LARGEUR */
    /* ----------------------------------------------------- */
    &.image_only {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        min-height: 50vh;
        max-height: 60vh;
        position: relative;

        .image {
            display: none;

            img {
                @include objFit;
            }
        }

        .content {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            @include flex(flex, column, nowrap, center, flex-start);

            padding: 10vh 1rem;
            background: linear-gradient(136.84deg, rgba($dark, 0.47) 0%, rgba(21, 21, 21, 0) 100%);

            .section_title {
                color: $light;
            }

            .text {
                p {
                    color: $light;
                    font-style: italic;
                }
            }
        }

        html.ios & {
            background: none;

            .image {
                img {
                    display: inline-block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* SECTIONS D'UN ARTICLE */
    /* ----------------------------------------------------- */
    &.post_sections {
        position: relative;
        margin: 4vh 0;

        body.page-template-seminaires & {
            .capacites {
                order: 2;
            }
        }

        h2 {
            margin-bottom: 1em;
            text-align: center;
        }

        p {
            text-align: justify;
        }

        .pattern {
            &.tomate-oignon {
                height: 100%;
                background-image: url($imagePath + "tomate-oignon.jpg");
                -webkit-background-size: contain;
                -moz-background-size: contain;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left center;
            }
        }

        .single_title {
            h2 {
                margin-bottom: 1em;
            }
        }

        /* (seminaires) */
        .capacite,
        .disponibilites {
            &_title {
                color: $col2;
                font-family: $ff1;
                // font-size: 1.5em;
            }

            &_texte {
                color: $dark;
                font-family: $ff1;
                font-size: 1em;
            }
        }

        /* (contact) */
        .coordonnees {
            .designation,
            .horaires_title {
                font-family: $ff1;
                font-size: 1.5em;
                color: $col2;
                margin-bottom: 2rem;
            }

            .designation {
                font-size: 2em;
            }

            .horaires_title {
                margin-top: 2rem;
            }

            .tel {
                color: $col1;
                font-weight: bold;
                font-family: $ff1;
                margin-bottom: 1rem;
                display: inline-block;

                a {
                    color: $dark;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* BRANCHEMENT DE PAGE */
    /* ----------------------------------------------------- */
    &.branchepage {
        padding: 4vh 4vw;

        .pages {
            /* Template ICONS */
            /* ----------------------------------------------------- */
            &.icons {
                @include flex(flex, row, wrap, flex-start, stretch);

                margin: 4vh 4vw;

                .page {
                    flex-basis: 100%;
                    margin: 1vh 0;
                    padding: 1rem 0;
                    border-bottom: 1px solid #D8D8D8;

                    &:last-child {
                        border-right: none;
                    }

                    > a {
                        @include flex(flex, column, nowrap, space-between, center);

                        color: $col4;
                        padding: 0 1rem;

                        i {
                            font-size: 4em;
                            margin-bottom: 2rem;
                        }

                        span {
                            font-size: 0.875rem;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        &:hover {
                            color: $dark;
                        }
                    }
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* GMAP */
    /* ----------------------------------------------------- */
    &.gmap {
        .filters_col {
            padding: 0 !important;
        }

        .filters_container {
            padding: 2rem 1rem;
            background-color: $col3;
            position: fixed;
            top: 0;
            bottom: 0;
            height: auto;
            width: 100%;
            z-index: 1404;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s linear, transform .4s $cubictrans;
            -webkit-transform: scale(.9);
            -moz-transform: scale(.9);
            -ms-transform: scale(.9);
            -o-transform: scale(.9);
            transform: scale(.9);

            select {
                border: 1px solid $light;
                border-radius: 2px;
                background-color: rgba($light,0.52);

                option {
                    color: $dark;
                }

                &::placeholder {
                    color: $dark;
                }
            }

            body.map-search-open & {
                opacity: 1;
                visibility: visible;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
                
            }

            &__close {
                position: absolute;
                top: 0;
                right: 0;
                padding: 1rem;

                a {
                    color: $light;
                }
            }
        }

        .list_modes {
            display: none;
        }

        .list_modes_mobile {
            position: absolute;
            top: 0;
            bottom: auto;
            width: 100%;
            background-color: rgba($light, .8);
            padding: .5rem;
            z-index: 402;
            @include flex(flex, row, nowrap, center, stretch);

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: center;

                li {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: top;
                    line-height: 1;

                }
            }

            .btn {
                border-radius: 0;
                padding: .4rem;
                margin: 0;

                & + & {
                    margin: 0;
                }
            }
        }

        .section_title {
            color: $light;
            font-family: $ff1;
            font-weight: bold;
            font-size: 2rem;
            position: relative;

            &:after {
                content: "";
                display: block;
                width: 40px;
                height: 3px;
                background-color: $light;
                margin-top: 1rem;
            }
        }

        .section_subtitle {
            color: $dark;
            text-transform: none;
            font-weight: normal;
            font-family: $ff1;
            margin-bottom: 1rem;
        }

        .search-map {
            text-align: center;
        }

        #map {
            height: 100vh !important;
        }

    }

    /* ----------------------------------------------------- */
    /* COLONNES FLEXIBLES */
    /* ----------------------------------------------------- */
    &.colonnes_flexibles {
        .column {
            padding: .5rem 0;

            &__title {
                // font-size: pte(36px, $fz_xxs);
                font-weight: 600;
                text-align: center;
                color: $col1;
            }

            &__text {
                p {
                    color: $coltext;

                    em {
                        color: $col1;
                    }
                }

                strong {
                    color: $col2;
                }

                section.col2 & {
                    p, strong {
                        color: $light;
                    }
                }
                p {
                    color: $col6;
                    font-weight: 600;
                    text-align: center;
                    
                }
            }

            &.col1 {
                .column-text {
                    p {
                        color: $col6;
                    }
                }
            }

            &__subtitle {
                color: $col1;
                font-family: $ff1;
                font-size: pte(36px, $fz);
                text-transform: none;
                line-height: 1.5;

                section.col2 & {
                    color: $light;
                }
            }

            &__image {
                height: 100%;

                img {
                    padding-bottom: 1rem;

                    @include objFit;
                }

            }

            &__video {
                iframe {
                    width: 100%;
                }
            }

            &__link {
                margin-top: 1rem;
            }

            &__icon {
                text-align: center;
                margin-bottom: 1rem;
                
                i {
                    font-size: 4rem;
                    color: $col3;
                }
            }

            &__icons {

                .column_title {
                    font-size: ptr(36px, $fz);
                }

                .icon {
                    @include flex(flex, column, nowrap, flex-start, center);
                    margin: 1rem 0;
                    cursor: pointer;
                    

                    &__icon {
                        @include hex('clip.svg');
                        margin-bottom: 1rem;
                        font-size: 3rem;
                        color: $light;
                        text-align: center;
                        -webkit-transition: $trans;
                        -moz-transition: $trans;
                        -ms-transition: $trans;
                        -o-transition: $trans;
                        transition: $trans;

                        i {
                            display: inline-block; 
                            padding: 2rem;
                        }

                        &:hover {
                            @include hex('clip2.svg');
                        }
                    }

                    &__desc {
                        padding: 0 1rem;

                        p {
                            font-size: 1rem;
                            color: $col1;
                            margin: 0;
                            text-align: center;
                        }
                    }

                    &:hover,
                    &.tab-active {
                        .icon {
                            &__icon {
                                @include hex('clip2.svg');
                            }
                            &__desc {
                                p {
                                    color: $col2;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }

            &__images {
                &:not(.carousel) {
                    @include flex(flex, column, nowrap, flex-start, stretch);
                }

                &.carousel {
                    .column-images-slider {
                        height: 50vh;

                        .image {
                            &__image {
                                height: 100%;

                                img {
                                    @include objfit();
                                }
                            }
                        }
                    }
                    .column-images-mini-slider {
                        height: ptr(150px, $fz);
                    }
                }

                .image {
                    flex-basis: 100%;
                    margin-bottom: 2rem;
                    padding: .5rem;

                    &__desc {
                        text-align: center;
                        font-weight: bold;
                        margin: 1rem 0;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

        }
    }

    /* ----------------------------------------------------- */
    /* BOX */
    /* ----------------------------------------------------- */
    &.txt-txt {

        .section_title {
            font-family: $ff1;
            color: $col5;
            font-size: pte(32px, $fz);
            font-weight: bold;
            text-align: center;
            text-shadow: 0 0 4px rgba($light, 8);
        }

        .region_box,
        .thematic_box {
            background-color: $col2;
            box-shadow: 0 4px 3px 0 rgba($dark, 0.2);
            padding: pte(24px, $fz);
            margin: 0 0 2rem 0;

            &__title {
                p {
                    font-family: $ff1;
                    font-weight: bold;
                    color: $light;
                    font-size: 2rem;

                    &:after {
                        content: "";
                        display: block;
                        width: 40px;
                        height: 3px;
                        background-color: $col3;
                    }
                }
            }

            &__content {
                select {
                    border: 1px solid $light;
                    border-radius: 2px;
                    background-color: rgba($light, 0.52);
                    margin: 0;
                }
            }

            .select_container {
                position: relative;
                font-family: $fficon;
                margin-bottom: 1rem;
                
                &:after {
                    content: "\e91d";
                    display: block;
                    position: absolute;
                    font-size: ptr(6px, $fz);
                    top: 50%;
                    right: 1rem;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                }
            }

        }

    }

    /* ----------------------------------------------------- */
    /* KEY FIGURES */
    /* ----------------------------------------------------- */
    &.keyfigures {
        .column-keyfigures {
            @include flex(flex, column, nowrap, flex-start, center);

            .keyfigure {
                flex-direction: column;
                margin: 0 0 2rem 0;
                flex-basis: 100%;

                &__icon {
                    order: 1;
                    @include hex('clip.svg');
                    -webkit-transition: $trans;
                    -moz-transition: $trans;
                    -ms-transition: $trans;
                    -o-transition: $trans;
                    transition: $trans;
                    filter: drop-shadow(3px 9px 2px #dddddd);

                    i {
                        display: block;
                        font-size: 4rem;
                        padding: 4rem;
                        color: $light;
                    }
                    
                    &:hover {
                        @include hex('clip2.svg');
                    }
                }

                &__number {
                    order: 2;
                }

                &__desc {
                    order: 3;
                    color: $col2;
                    font-weight: bold;
                    font-size: ptr(32px, $fz);
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* Keyfigures */
    /* ----------------------------------------------------- */
    &.keyfigures-section {
        .title {
            background-color: $col3;
            position: relative;

            &__container {
                @include flex(flex, column, wrap, flex-start, flex-start);
                padding: 1rem 0;
            }

            &__icon {
                font-size: 110px;
                opacity: .3;
            }

            h2 {
                color: $light;

                span {
                    text-transform: uppercase;
                    font-weight: bold;
                }

                em {
                    display: block;
                    font-weight: normal;
                    font-style: italic;
                }
            }

        }

        .figures {
            background-color: rgba($col3, .57);

            &__container {
                padding: 1rem;
                @include flex(flex, column, nowrap, flex-start, center);
            }

            .figure {
                text-align: center;
                padding: 2rem 1rem;

                &__icon {
                    @include flex(inline-flex, row, nowrap, center, center);
                    width: ptr(125px, $fz);
                    height: ptr(125px, $fz);
                    text-align: center;
                    border-radius: ptr(125px, $fz);
                    overflow: hidden;
                    background: rgba($light, 0.27);
                    border: 12px solid rgba($light, 0.27);
                    align-items: center;

                    i {
                        font-size: 3rem;
                        text-align: center;
                        color: #A38600;
                    }
                }

                &__number {
                    color: #A38600;
                    font-family: $ff1;
                    font-size: ptr(60px, $fz);
                    font-weight: bold;
                    text-align: center;
                }

                &__description {
                    color: #A38600;
                    font-family: $ff1;
                    font-size: ptr(30px, $fz);
                    font-style: italic;
                    text-align: center;
                    font-weight: lighter;
                }

            }
        }

        .contact {
            background-color: rgba($col3,0.21);

            &__container {
                padding: 1rem;
            }

            &__title {
                font-family: $ff1;
                color: #A38600;
                font-weight: bold;
                font-size: ptr(34px, $fz);

                &:after {
                    content: "";
                    display: block;
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    width: 40px;
                    height: 3px;
                    background-color: $col3;
                }

            }

            p {
                margin: 0;

                b {
                    color: $dark;
                    font-family: $ff1;
                    font-size: ptr(18px, $fz);
                    font-weight: bold;
                }

                a {
                    color: $dark;

                    &:hover {
                        color: darken($col3, 10%);
                    }
                }

                .tel {
                    display: inline-block;
                    margin-top: .5rem;
                }
            }

            .btn {
                margin-top: 2rem;
            }

        }
    }

    /* ----------------------------------------------------- */
    /* TIMELINE */
    /* ----------------------------------------------------- */
    &.timeline {
        position: relative;
        
        /* Barre */
        /* ----------------------------------------------------- */
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 1rem;
            bottom: 1rem;
            left: 50%;
            -webkit-transform: translate(-50%,0);
            -ms-transform: translate(-50%,0);
            -o-transform: translate(-50%,0);
            transform: translate(-50%,0);
            width: 3px;
            height: auto;
            background-color: $col2;
            z-index: 1;
        }

        /* Item date */
        /* ----------------------------------------------------- */
        .timeline {
            &__date {
                @include flex(flex, column, wrap, flex-start, center);
                position: relative;
                z-index: 5;
                margin: 0 0 3rem 0;

                &-left {
                    flex-basis: 100%;
                    width: 100%;

                }

                &-right {
                    flex-basis: 100%;
                    width: 100%;
                }

                &-year {
                    font-size: ptr(36px, $fz);
                    color: $col1;
                    font-weight: 800;
                    display: none;
                }

                &-image {
                    display: none;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                /* hexagone */
                /* ----------------------------------------------------- */
                &-hex {
                    @include hex('clip_border.svg');
                    @include flex(flex, column, nowrap, center, center);
                    width: 100%;
                    height: auto;
                    background-color: $light;
                    text-align: center;
                    min-height: calc(100vw * #{$ratio});
                    cursor: pointer;

                    &-title {
                        font-size: ptr(24px, $fz);
                        font-weight: 800;
                        color: $col1;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    &-icon {
                        font-size: ptr(36px, $fz);
                        color: $col1;
                        text-align: center;
                    }

                    &-year {
                        font-size: ptr(36px, $fz);
                        color: $col1;
                        font-weight: 600;
                    }

                    &-text {
                        opacity: 0;
                        visibility: hidden;
                        display: none;

                        p {
                            color: $coltext;
                            font-weight: 500;
                        }
                    }

                }

                /* TPL sans hexagone */
                /* ----------------------------------------------------- */
                &-no_hex {
                    align-items: flex-start;
                    background-color: $light;
                    padding: 1rem 0;

                    .timeline__date {

                        &-year {
                            display: none;
                        }

                        &-image {
                            display: block;
                        }

                        &-hex {
                            &-year {
                                position: relative;

                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    right: 0;
                                    z-index: 1;
                                    width: auto;
                                    height: 1px;
                                    background-color: $col2;
                                }

                                span {
                                    position: relative;
                                    z-index: 2;
                                    background-color: $light;
                                    padding-right: 1rem;
                                }
                            }

                            &-title {
                                text-align: left;
                            }

                            &-text {
                                opacity: 1;
                                visibility: visible;
                                display: block;
                            }
                        }

                    }

                    &.timeline__date-even {

                        .timeline__date-left {
                            order: 2;
                        } 
                        .timeline__date-right {
                            order: 1;
                        } 
                    }

                    &.timeline__date-odd {
                        .timeline__date-left {
                            order: 1;
                        } 
                        .timeline__date-right {
                            order: 2;
                        } 
                    }
                }

                /* Couleurs */
                /* ----------------------------------------------------- */
                &-bleu {
                    .timeline__date {
                        &-hex {
                            background-image: url($imagePath + 'clip.svg');

                            &-title,
                            &-icon,
                            &-year {
                                color: $light;
                            }

                            &-text {
                                p {
                                    color: $light;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* Branchepage */
    /* ----------------------------------------------------- */
    &.branchepage {
        .pages {
            &.grid {
                @include flex(flex, column, nowrap, flex-start, center);

                .grid-item {
                    flex-basis: 100%;

                    &-title {
                        display: inline-block;
                        text-align: left;
                        font-weight: 500;
                        color: $col1;
                        width: 75%;
                        text-transform: uppercase;
                        -webkit-transform: translate(0, -1.5rem);
                        -ms-transform: translate(0, -1.5rem);
                        -o-transform: translate(0, -1.5rem);
                        transform: translate(0, -1.5rem);
                        -webkit-transition: $trans;
                        -moz-transition: $trans;
                        -ms-transition: $trans;
                        -o-transition: $trans;
                        transition: $trans;
                        padding: 1rem;
                        background-color: $light;
                    }

                    &:hover {
                        .grid-item {
                            &-title {
                                color: $col2;
                            }
                        }
                    }
                }
            }
        }
    }



    // Padding de la seconde section apres le slider
    &.slider + & {
        padding-top: 4rem;
    }

    // on enleve la barre sous le titre de la 1ere section
    &:nth-child(2) {
        .section_title {
            &:after {
                display: none;
            }
        }
    }
}

/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* RESPONSIVE */
/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILE : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {
    section {

        &.separator {
            .sep {
                width: calc(100% - 2rem);
            }
        }

        /* Social Wall */
        /* ----------------------------------------------------- */
        &.social_wall {
            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-xxs {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }
            }

            .pattern {
                &.top-center {
                    width: 65%;
                }

                &.bottom-left {
                    width: 25%;
                }
            }
        }

        &.colonnes_flexibles {

            .column {
                &-images {
                    &:not(.carousel) {
                        @include flex(flex, row, wrap, center, stretch);
                    }

                    .image {
                        flex-basis: 25%;
                    }
                }
            }

            &.edito {
                .news {
                    .column_inner {
                        padding: 5rem;
                    }

                    .news-item {
                        margin-bottom: 2rem;

                        &:nth-child(2) {
                            display: block;
                        }

                        &:nth-child(3) {
                            display: block;
                        }
                    }
                }
            }
        }

        &.timeline {
            .timeline {
                &__date {
                    .timeline__date-hex {
                        &-text {
                            opacity: 1;
                            visibility: visible;
                            display: block;
                        }
                    }
                }
            }
        }

    }
}

/* ----------------------------------------------------- */
/* MOBILE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
    section {

        &.title {
            .col_title {
                .title {
                    padding: 1rem;
                }
            }
        }

        /* Social wall */
        /* ----------------------------------------------------- */
        &.social_wall {
            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-xs {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }
            }

            .pattern {
                &.top-center {
                    width: 50%;
                }

                &.bottom-left {
                    width: 15%;
                }
            }
        }

        /* Text Image */
        /* ----------------------------------------------------- */
        &.txt_img,
        &.img_txt {
            overflow: visible;
            padding: 2rem 0;

            .txt {
                
            }

            .img {
            }
        }

        /* txt txt */
        /* ----------------------------------------------------- */
        &.txt-txt {
            .section_title {
                width: 60%;
                margin: 0 auto 3em auto;
            }
        }

        /* BRANCHEMENT DE PAGE */
        /* ----------------------------------------------------- */
        &.branchepage {
            .pages {

                /* Template GRID */
                /* ----------------------------------------------------- */
                &.grid {
                    @include flex(flex, row, wrap, flex-start, stretch);

                    .grid-item {
                        flex-basis: 50%;

                        > a {
                            padding: 1rem;
                            display: block;
                            
                        }

                        .image-container {
                            height: 20vh;

                            .img {
                                width: 100%;
                                height: 100%;
                            }

                            img {
                                @include objfit();
                            }
                        }
                    }
                }

                /* Template ICONS */
                /* ----------------------------------------------------- */
                &.icons {
                    .page {
                        flex-basis: 50%;

                        &:nth-child(2n + 1) {
                            border-right: 1px solid #D8D8D8;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
        }

        /* Map */
        /* ----------------------------------------------------- */
        &.gmap {
            position: relative;

            .filters_absolute {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1001;
                height: 100%;

                .row.flex {
                    height: 100%;
                }

                .filters_col {
                    background-color: $col1;
                    height: 100%;
                }
            }
        }

        /* Edito */
        /* ----------------------------------------------------- */
        &.colonnes_flexibles {
            .column {
                &_inner {
                    height: 100%;
                }

                &_title {
                    &.flex {
                        @include flex(flex, row, nowrap, flex-start, center);
                    }

                    i {
                        font-size: 3em;
                        flex-basis: calc(100% / 6);
                    }
                }

                &__icon {
                    i {
                        font-size: 3rem;
                    }
                }

                &-icons {
                    @include flex(flex, row, wrap, center, stretch);

                    .icon {
                        flex-basis: 50%;
                    }
                }

                &-images {
                    margin: 0 auto;

                    &:not(.carousel) {
                        @include flex(flex, row, wrap, center, center);
                    }

                    .image {
                        flex-basis: 25%;
                    }
                }
            }

            &.certifications {
                .column {
                    &-images {
                        width: ptr(768px, $fz_md);
                        margin: 2rem auto;
                    }
                }
            }

            &.edito {
                .news {
                    .column_inner {
                        padding: 8rem;
                    }

                    .news-item {
                        &:nth-child(3) {
                            display: block;
                        }
                    }

                    .column-link {
                        .hex2 {
                            font-size: 1rem;
                            width: ptr(150px, $fz_xs);
                            height: ptr(150px, $fz_xs);
                            padding: 1rem;
                        }
                    }
                }
            }

            &.avantages {
                padding: ptr(90px, $fz) 0;

                .column {
                    &-image {
                        margin: 1rem 0;
                        max-height: ptr(280px, $fz_xs);
                        overflow: hidden;
                    }
                }
            }
        }

        /* Section chiffres cles */
        /* ----------------------------------------------------- */
        &.keyfigures {
            .column-keyfigures {
                @include flex(flex, row, wrap, flex-start, stretch);
                margin: 2rem 0;

                .keyfigure {
                    flex-basis: 50%;
                }
            }
        }



    }
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    section {

        &.title {
            .col_title {
                .title {
                    padding: 1.5rem;
                }
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.social_wall {
            .section_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-sm {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }

                &-item {
                    .over {
                        span {
                            font-size: pte(12px, $fz);
                        }
                    }
                }
            }

            .pattern {
                &.top-center {
                    width: 35%;
                }

                &.bottom-left {
                    width: 10%;
                }
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.bandeau {
            .logo {
                display: block;
                margin-top: 6.25em;
            }

            .title {
                padding-top: 0;

                .page-title {
                    font-size: ptr(50px, $fz_lg);
                }
            }

            .pattern {
                &.bottom-left,
                &.top-right,
                &.bottom-right {
                    width: 60%;
                }
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.image_only {
            min-height: 80vh;
            max-height: 85vh;
        }

        /* Txt - txt */
        /* ----------------------------------------------------- */
        &.txt-txt {
            .region_box,
            .thematic_box {
                .select_container {
                    margin-bottom: 2rem;
                }
            }
        }

        /* TXT-IMG / IMG_TXT */
        /* ----------------------------------------------------- */
        &.txt_img,
        &.img_txt {
            .txt {
            }

            .img {
                .image-container {
                    height: 100%;
                }
            }
        }

        /* BRANCHEMENT DE PAGE */
        /* ----------------------------------------------------- */
        &.branchepage {
            .pages {
                /* Template ICONS */
                /* ----------------------------------------------------- */
                &.icons {
                    .page {
                        flex-basis: calc(100% / 3);
                        border-right: none;
                        border-bottom: none;

                        &:nth-child(2n + 2) {
                            border-right: 1px solid #d8d8d8;
                        }
                    }
                }
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.post_thumbnail {
            .post_thumbnail_image {
                max-height: 33vh;
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.post_sections {
            body.page-template-seminaires & {
                .capacites {
                    order: 0;
                }
            }

            h2,
            p,
            ul li,
            ol li {
                text-align: left;
            }
        }

        /* Gmap */
        /* ----------------------------------------------------- */
        &.gmap {
            #map {
                height: 100% !important;
                min-height: 100vh;
            }
        }

        /* Colonnes */
        /* ----------------------------------------------------- */
        &.colonnes_flexibles {
            &.edito {
                .column {
                    .news {
                        .column-title {
                            .column_title {
                                font-size: 1.75rem;
                            }
                        }

                        .news-item {
                            .title {
                                font-size: ptr(24px, $fz);
                            }
                        }
                    }
                }
            }

            .column {
                &-icons {
                    .icon {
                        flex-basis: calc(100% / 3);
                    }
                }
            }
        }

    }
}

/* ----------------------------------------------------- */
/* TABLET PORTRAIT : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    section {

        .section_title {
            // font-size: pte(64px, $fz_md)
            margin-bottom: 2rem;
        }

        &.title {
            .col_title {
                .title {
                    padding: 2rem;
                }
            }
        }

        /* Branchement de page */
        /* ----------------------------------------------------- */
        &.branchepage {
            .pages {
                /* Template ICONS */
                /* ----------------------------------------------------- */
                &.icons {
                    margin: 4vh 0;
                    justify-content: center;

                    .page {
                        flex-basis: 18%;
                        border-bottom: none;
                        border-right: 1px solid #d8d8d8;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
        }

        /* Social Wall */
        /* ----------------------------------------------------- */
        &.social_wall {
            padding: 7vh 4vw 10vh;

            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-md {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }
            }

            .tabs {
                .tab {
                    font-size: 20px;
                }
            }
        }

        /* bandeau */
        /* ----------------------------------------------------- */
        &.bandeau {
            min-height: ptr(360px, $fz);

            body.page-template-seminaires & {
                .pattern.top-right {
                    svg {
                        height: 100%;
                    }
                }
            }

            .title {
                .page-title {
                    font-size: ptr(60px, $fz_lg);
                }
            }

            .page-excerpt {
                font-size: pte(24px, $fz_md);
            }
        }

        /* txt_img */
        /* ----------------------------------------------------- */
        &.txt_img {
            padding: ptr(55px, $fz_md) 0;

            .txt {
                order: 1;
            }
            .img {
                order: 2;
            }
        }

        /* img_txt */
        /* ----------------------------------------------------- */
        &.img_txt {
            padding: ptr(55px, $fz_md) 0;

            .txt {
                order: 2;
            }
            .img {
                order: 1;
            }
        }


        /* Post thumbnail */
        /* ----------------------------------------------------- */
        &.post_thumbnail {
            .post_thumbnail_image {
                max-height: 50vh;
            }
        }

        /* Post sections */
        /* ----------------------------------------------------- */
        &.post_sections {
            margin: 8vh 0;

            .coordonnees {
                .tel {
                    font-size: pte(28px, $fz_md);
                }
            }
        }

        /* Colonnes flexibles */
        /* ----------------------------------------------------- */
        &.colonnes_flexibles {

            .column {
                &-icons {
                    .icon {
                        flex-basis: calc(100% / 4);
                    }
                }

                &_title {
                    // font-size: ptr(24px, $fz);
                }
            }

            &.certifications {

            }

            &.edito {
                .frise {
                    .column_inner {
                        height: 100%;
                    }


                    .column-text {
                        height: 100%;

                        &:before {
                            top: 2rem;
                            bottom: .5rem;
                            left: 50%;
                            right: auto;
                            -webkit-transform: translate(-50%,0);
                            -ms-transform: translate(-50%,0);
                            -o-transform: translate(-50%,0);
                            transform: translate(-50%,0);
                            width: 3px;
                            height: auto;
                        }

                        &:after {
                            top: auto;
                            bottom: 0;
                            left: 50%;
                            right: auto;
                            -webkit-transform: translate(-50%,0);
                            -ms-transform: translate(-50%,0);
                            -o-transform: translate(-50%,0);
                            transform: translate(-50%,0);
                        }

                        i {
                            width: 100%;
                            height: auto;
                            line-height: 1;
                            padding: .5rem;
                        }
                    }
                }

                .news {

                    .column-title {
                        .column_title {
                            margin-bottom: 0;
                        }
                    }

                    .column_inner {
                        background-position: top center;
                        padding: 8rem 5rem;
                        min-height: 0;
                        // min-height: calc(100% * #{$ratio});
                    }

                    .news-item {
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
            }

            /* ----------------------------------------------------- */
            /* AVANTAGES */
            /* ----------------------------------------------------- */
            &.avantages {

                .row.flex {
                    align-items: center;
                }

                .column {

                    &.icon {
                        text-align: center;

                        .column_immer {
                            @include flex(flex, column, nowrap, center, center);
                        }

                        i {
                            color: $col2;
                            font-size: 10rem;
                        }
                    }

                    &_title {
                        text-align: left;
                    }

                }
            }
        }


        &.branchepage {
            .pages.grid {
                .grid-item {
                    flex-basis: calc(100% / 3);
                }
            }
        }
    }
}


/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
    section {
        padding: 3rem 0;

        // &:first-child {
        //     padding-top: 0;
        // }

        &.title {
            .col_title {
                .title {
                    padding: 3rem 2rem;
                }
            }
        }

        .section_title {
            font-size: ptr(45px, $fz);
            margin-bottom: 3rem;
        }

        /* Colonnes flexibles */
        /* ----------------------------------------------------- */
        &.colonnes_flexibles {
            .column {
                &-title {
                    padding: 0;
                }

                &_title {
                    // font-size: ptr(32px, $fz);
                    margin-bottom: 3rem;
                }

                &-images {
                    &.carousel {
                        .column-images-slider {
                            height: 40vh;

                            .slick-prev {
                                left: -1rem;
                            }

                            .slick-next {
                                right: -1rem;
                            }
                        }
                    }
                }
            }

            &.edito {
                .news {
                    .column {
                        &-link {
                            left: 22%;
                            -webkit-transform: translate(0,0);
                            -ms-transform: translate(0,0);
                            -o-transform: translate(0,0);
                            transform: translate(0,0);
                        }
                    }
                }

                .frise {
                    .column-text {
                        i {
                            padding: 1rem;
                        }
                    }
                }
            }
        }

        /*  */
        /* ----------------------------------------------------- */
        &.social_wall {
            // padding: 7vh 4vw 10vh;

            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-lg {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }

                &-item {
                    .over {
                        .content {
                            width: 100%;
                            height: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        &.bandeau {
            height: 60vh;
        }

        &.post_sections {
            margin: 12vh 0;
        }

        /*  */
        /* ----------------------------------------------------- */
        &.txt-large,
        &.txt_img,
        &.img_txt {
        }

        /* Branchepage */
        /* ----------------------------------------------------- */
        &.branchepage {
            padding: 10vh 4vw;
        }

        /* Gmap */
        /* ----------------------------------------------------- */
        &.gmap {
            padding: 0;

            .filters_container {
                padding: 10vh 4.375em;
            }

            .list_modes {
                left: 4.375em;
                right: 4.375em;
            }

            .categories {
                ul {
                    li {
                        display: block;

                        .category_filter {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        /* TXT IMG / IMG TXT */
        /* ----------------------------------------------------- */
        &.txt_img,
        &.img_txt {
            .row.flex {
                align-items: center;
            }
        }

        /* txt txt */
        /* ----------------------------------------------------- */
        &.txt-txt {
            .section_title {
                width: 60%;
                margin: 0 auto 3em auto;
                font-size: ptr(45px, $fz);
            }

            .region_box {
                margin-left: 16%;
                padding: ptr(48px, $fz) ptr(68px, $fz) ptr(68px, $fz) ptr(68px, $fz);

                &__content {
                    select {
                        margin-bottom: 2rem;
                    }
                }
            }

            .thematic_box {
                margin-right: 16%;
                padding: ptr(48px, $fz) ptr(68px, $fz) ptr(68px, $fz) ptr(68px, $fz);

                &__content {
                    select {
                        margin-bottom: 2rem;
                    }
                }
            }

        }

        &.colonnes_flexibles {
            &.edito {
                .news {
                    -webkit-transform: translate(0, -10%);
                    -ms-transform: translate(0, -10%);
                    -o-transform: translate(0, -10%);
                    transform: translate(0, -10%);

                    .news-item {
                        &:nth-child(3) {
                            display: block;
                        }
                    }

                    .column-text {
                        width: 50%;
                        margin: 0 auto;
                    }
                }
            }
        }

        &.timeline {
            .timeline {
                &__date {
                    &-hex {
                        min-height: calc(25vw * #{$ratio});
                    }
                }
            }
        }

    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-xl} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
    section {
        padding:  2rem 0;

        /* Social Wall */
        /* ----------------------------------------------------- */
        &.social_wall {
            .socialwall {
                &-list {
                    @for $i from 1 through 12 {
                        .col-#{$i}-xl {
                            .socialwall-item {
                                > a {
                                    height: calc(92vw / (12 / #{$i} + 1) );
                                }
                            }
                        }
                    }
                }
            }
        }

        &.colonnes_flexibles {
            .column {
                &-title {
                    padding: 0;
                }

                &_title {
                    // font-size: ptr(36px, $fz);
                    margin-bottom: 3rem;
                }
            }

            &.edito {
                .frise {
                    .column-text {
                        i {
                            padding: 1.5rem;
                        }
                    }
                }
            }

            &.map-clients {
                .column-text {
                    padding-left: calc(100% / 6);
                }
            }
        }


        /* txt txt */
        /* ----------------------------------------------------- */
        &.txt-txt {
            .section_title {
                width: 50%;
            }
        }

    }
}

@media (min-height: 795px) {

    section {
        &.gmap {
            #map {
                max-height: 100%;
                min-height: 0;
            }
        }
    }

}
