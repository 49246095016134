/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MIXIN.SCSS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* FONT FACE */
/* ----------------------------------------------------- */
@mixin font-face($name, $file, $weight, $style) {
  @font-face {
    font-family: $name;
    src: url("#{$file}.eot");
    src: url("#{$file}.eot?#iefix") format("embedded-opentype"), url("#{$file}.woff") format("woff"), url("#{$file}.ttf") format("truetype"), url("#{$file}.svg") format("svg");
    font-weight: $weight;
    font-style: $style;
  }
}

/* ----------------------------------------------------- */
/* KEYFRAMES */
/* ----------------------------------------------------- */
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

/* ----------------------------------------------------- */
/* CENTRE UNE IMAGE */
/* ----------------------------------------------------- */
@mixin imgCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

/* ----------------------------------------------------- */
/* PREFIXES : Transition */
/* ----------------------------------------------------- */
@mixin transition($trans) {
  -ms-transition: $trans;
  -o-transition: $trans;
  -moz-transition: $trans;
  -webkit-transition: $trans;
  transition: $trans;
}

/* ----------------------------------------------------- */
/* PREFIXES : Transform */
/* ----------------------------------------------------- */
@mixin transform($transf) {
  -ms-transform: $transf;
  -o-transform: $transf;
  -moz-transform: $transf;
  -webkit-transform: $transf;
  transform: $transf;
}

/* ----------------------------------------------------- */
/* CENTRE UN ELEMENT */
/* ----------------------------------------------------- */
@mixin centerEl {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* ----------------------------------------------------- */
/* BASE GRADIENT */
/* ----------------------------------------------------- */
@mixin degrade {
  background: rgb(219, 236, 245);
  background: linear-gradient(222deg, rgba(219, 236, 245, 1) 0%, rgba(152, 184, 200, 1) 100%);
}

/* ----------------------------------------------------- */
/* CENTRE UN BACKGROUND QUAND ON LE MET EN INLINE */
/* ----------------------------------------------------- */
@mixin centerBG {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

/* ----------------------------------------------------- */
/* OBJECT FIT AVEC DETECTIZR */
/* ----------------------------------------------------- */
@mixin objFit {
  html.objectfit & {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  html:not(.objectfit) & {
    width: 100%;
    height: auto;
  }
}

/* ----------------------------------------------------- */
/* TRIANGLES */
/* ----------------------------------------------------- */
@mixin triangle($orientation, $color, $width, $height) {
  width: 0;
  height: 0;

  @if $orientation == "top" {
    top: 1px;
    left: 50%;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    border-top: $height solid transparent;
    border-right: $width solid transparent;
    border-bottom: $height solid $color;
    border-left: $width solid transparent;
  }

  @if $orientation == "right" {
    top: 50%;
    right: 1px;
    -webkit-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    border-top: $height solid transparent;
    border-right: $width solid transparent;
    border-bottom: $height solid transparent;
    border-left: $width solid $color;
  }

  @if $orientation == "bottom" {
    bottom: 1px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    border-top: $height solid $color;
    border-right: $width solid transparent;
    border-bottom: $height solid transparent;
    border-left: $width solid transparent;
  }

  @if $orientation == "left" {
    top: 50%;
    left: 1px;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    border-top: $height solid transparent;
    border-right: $width solid $color;
    border-bottom: $height solid transparent;
    border-left: $width solid transparent;
  }
}

/* ----------------------------------------------------- */
/* Display flex */
/* ----------------------------------------------------- */
@mixin flex($flex: flex, $direction: row, $wrap: nowrap, $jc: flex-start, $ai: flex-start) {
  display: -webkit-#{$flex};
  display: -moz-#{$flex};
  display: -ms-#{$flex};
  display: -o-#{$flex};
  display: #{$flex};
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $jc;
  align-items: $ai;
}

/* ----------------------------------------------------- */
/* Object Fit */
/* ----------------------------------------------------- */
@mixin objfit($fit: cover, $pos: center) {
  width: 100%;
  height: 100%;
  object-fit: $fit;
  object-position: $pos;
}

/* ----------------------------------------------------- */
/* Hexagones */
/* ----------------------------------------------------- */
@mixin hex($svg) {

  background-image: url($imagePath + $svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FONCTIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* CONVERSION PX -> EM */
/* ----------------------------------------------------- */
@function pte($px, $fz) {
  @return $px / $fz * 1em;
}

/* ----------------------------------------------------- */
/* CONVERSION PX -> REM */
/* ----------------------------------------------------- */
@function ptr($px, $fz) {
  @return $px / $fz * 1rem;
}

/* ----------------------------------------------------- */
/* CONVERSION PX -> % */
/* ----------------------------------------------------- */
@function ptp($lg, $ct) {
  @return $ct / 100% * $lg;
}
