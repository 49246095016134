/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FORUM */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.forum {

	/* ----------------------------------------------------- */
	/* MOTEUR DE RECHERCHE */
	/* ----------------------------------------------------- */
	&__search {
		padding: 0;
		-webkit-transform: translate(0, -2.5rem);
		-moz-transform: translate(0, -2.5rem);
		-ms-transform: translate(0, -2.5rem);
		-o-transform: translate(0, -2.5rem);
		transform: translate(0, -2.5rem);

		&_container {
			background-color: $col5;
			padding: 2rem;
		}

		&_title {
			font-weight: bold;
			color: $light;
			font-size: ptr(24px, $fz);
			margin-bottom: 1rem;
		}
	}

	/* ----------------------------------------------------- */
	/* RESULTATS */
	/* ----------------------------------------------------- */
	&__results {

		&_title {
			font-size: ptr(24px, $fz);
			font-weight: bold;
			color: $col1;
			text-transform: uppercase;
			text-align: center;
			
			i {
				display: block;
				font-size: ptr(72px, $fz);
				margin-bottom: 1rem;
				color: $col3;
			}
		}

		&_accordion {
			margin: 4rem 0 0 0;

			&_item {
				border-bottom: 1px solid $col7;

				&_header {
					cursor: pointer;
					@include flex(flex, row, nowrap, space-between, stretch);

					&_icon {
						flex-basis: calc(100% / 12 * 2);
						text-align: center;
						
						i {
							font-size: ptr(38px, $fz);
							color: $col3;
						}
					}

					&_title {
						flex-basis: calc(100% / 12 * 8);
						padding: 0 0.25rem;

						.title {
							font-size: ptr(18px, $fz);
							font-weight: bold;
							color: $col3;
							margin-bottom: .5rem;
						}

						.details {
							font-size: ptr(12px, $fz);
							font-weight: normal;
							color: #979797;
						}
					}

					&_answers {
						flex-basis: calc(100% / 12 * 2);
						text-align: center;
						
						.number {
							font-size: ptr(18px, $fz);
							font-weight: bold;
							color: $col3;
							margin-bottom: 0;
						}

						.answers {
							font-size: ptr(12px, $fz);
							font-weight: 500;
							color: #979797;
						}
					}
				}


				&_body {
					padding: 0 calc(100% / 12 * 2);
					position: relative;
					overflow: hidden;
					max-height: 0;
					-webkit-transition: $trans;
					-moz-transition: $trans;
					-ms-transition: $trans;
					-o-transition: $trans;
					transition: $trans;

					&_answer {
						position: relative;
						
						&:before {
							content: "\e969";
							font-family: $fficon;
							position: absolute;
							top: 0;
							left: 0;
							color: $col6;
							font-size: ptr(28px, $fz);
							-webkit-transform: translate(-110%,0%);
							-ms-transform: translate(-110%,0%);
							-o-transform: translate(-110%,0%);
							transform: translate(-110%,0%);
						}

						&_text {
							p {
								margin: 0;
								font-size: ptr(12px, $fz);
							}
						}

						&_details {
							@include flex(flex, row, nowrap, flex-start, flex-start);
							margin-top: 1rem;

							.avatar {
								flex-basis: 20%;
								margin: 0;

								img {
									border-radius: 100em;
									overflow: hidden;
								}
							}

							.author-date {
								flex-basis: 80%;
								padding-left: .5rem;
								line-height: 1;

								.date {
									display: block;
									font-size: ptr(12px, $fz);
									font-weight: normal;
									color: #979797;
								}

								.author {
									font-size: ptr(12px, $fz);
									font-weight: bold;
									color: $col1;
									line-height: 1;
								}
							}
						}

						&_btn {
							margin: .5rem 0;
							text-align: center;
						}
					}

					&.visible {
						max-height: none;
					}
				}

				&_footer {
					overflow: hidden;
					padding: 0 calc(100% / 12 * 2);
					background-color: $col6;
					max-height: 0;
					-webkit-transition: $trans;
					-moz-transition: $trans;
					-ms-transition: $trans;
					-o-transition: $trans;
					transition: $trans;
					

					&_form {

						&_title {
							color: $light;
							font-size: ptr(24px, $fz);
							font-weight: bold;
							margin-bottom: 1rem;
						}

						.form-group {
							&.form-submit {
								.btn {
									width: 100%;
								}
							}
						}

					}

					&.visible {
						padding: 1rem calc(100% / 12 * 2);
						max-height: 100vh;
					}
				}


			}
		}

	}

	/* ----------------------------------------------------- */
	/* QUESTIONNAIRE */
	/* ----------------------------------------------------- */
	&__form_question {
		padding: 1rem;
		background-color: $col4;
		position: relative;

		&:before,
		&:after {
			display: block;
			position: absolute;
			z-index: 1;
			width: 30%;
			height: auto;
			opacity: .2;
		}

		&:before {
			content: url($imagePath + 'pattern_bottom_left.svg');
			left: 0;
			bottom: 0;
		}

		&:after {
			content: url($imagePath + 'pattern_top_right.svg');
			right: 0;
			top: 0;
		}

		.container {
			position: relative;
			z-index: 2;
		}

		&_title,
		&_title_empty {
			.title {
				font-size: ptr(24px, $fz);
				font-weight: bold;
				color: $light;
				margin-bottom: 1rem;
			}
		}

		&_title_empty {
			display: none;
		}

		.form-group {
			&.form-submit {
				text-align: right;
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.forum {

		/* ----------------------------------------------------- */
		/* MOTEUR DE RECHERCHE */
		/* ----------------------------------------------------- */
		&__search {
			&_form {
				form {
					@include flex(flex, row, nowrap, space-between, stretch);

					.form-group {
						&:not(:last-child) {
							padding: 0 0.5rem 0 0;
						}

						&.form-text {
							flex-basis: 50%;
						}
						&.form-select {
							flex-basis: 30%;
						}
						&.form-submit {
							flex-basis: 20%;

							.form-field {
								height: 100%;

								.btn {
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					.form-field {
						margin-bottom: 0;
					}

					.select_container {
						margin-bottom: 0;
					}
				}
			}
		}

		/* ----------------------------------------------------- */
		/* RESULTATS */
		/* ----------------------------------------------------- */
		&__results {
			&_accordion {
				&_item {
					&_body {
						&_answer {
							&_details {
								align-items: center;

								.avatar {
									max-width: ptr(75px, $fz_xs);
								}
							}
						}
					}
				}
			}
		}

		/* ----------------------------------------------------- */
		/* FORMULAIRE */
		/* ----------------------------------------------------- */
		&__form_question {

			&:before,
			&:after {
				width: 11%;
			}
		}

	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.forum {
		&__form_question {
			&_title {
				&_empty {
					display: block;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.forum {
		&__results {
			&_accordion {
				&_item {
					&_header {
						&_icon,
						&_answers {
							flex-basis: calc(100% / 12);
						}

						&_title {
							flex-basis: calc(100% / 12 * 10);
						}
					}
				}
			}
		}

		&__form_question {
			padding: 2rem 0;

			&_title,
			&_title_empty {
				.title {
					margin-bottom: 2rem;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.forum {

		&__search {
			&_container {
				padding: 3rem calc(100% / 12);
			}
		}

		&__form_question {
			padding: 3rem 0;

			&_title,
			&_title_empty {
				.title {
					margin-bottom: 3rem;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.forum {
		&__form_question {
			padding: 4rem 0;

			&_title,
			&_title_empty {
				.title {
					margin-bottom: 4rem;
				}
			}
		}
	}

}

