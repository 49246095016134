/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* EVENTS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
section {
    &.events {
        /* ----------------------------------------------------- */
        /* LOADER */
        /* ----------------------------------------------------- */
        .events-loader {
            background-color: rgba($dark, 0.65);
            position: absolute;
            top: 2rem;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            opacity: 0;
            visibility: hidden;
            transition: $trans;

            &.display {
                opacity: 1;
                visibility: visible;
            }
        }

        /* ----------------------------------------------------- */
        /* Template HOME */
        /* ----------------------------------------------------- */
        .events-home {

            .section_title {
                color: $light;
                margin-bottom: 0;
                font-weight: 600;

                &:after {
                    display: none;
                }
            }

            .all-items-column {
                @include flex(flex, column, nowrap, flex-end, flex-end);
            }

            .filters {
                // +flex(flex, row, wrap, flex-end, flex-end)
         
            }

            &-filters {
                .events-filter {
                    &:not(.all_events) {
                        display: none;
                    }

                    & + & {
                        margin: 0 !important;
                    }

                    &.active {
                        background-color: $col2;
                    }
                }
            }

            // .events {

            //     // Template carousel 
            //     &_carousel {

            //     }

            // }

            .events-items {
                margin-top: 2rem;

                .event-item {
                    background-color: transparent;
                    position: relative;
                    height: 100%;

                    &-container {
                        margin-bottom: 1rem;
                    }

                    &-date {
                        font-family: $ff1;
                        color: $col5;
                        font-size: pte(24px, $fz);
                        font-weight: bold;
                        text-transform: uppercase;
                        padding: .5rem 0;
                    }

                    &-content {
                        @include flex(flex, row, nowrap, flex-start, flex-start);
                    }

                    &-image {
                        height: 190px;

                        img {
                            width: 100%;
                            height: 100%;
                            @include objfit();
                        }
                    }

                    &-title {
                        font-family: $ff1;
                        color: $light;
                        font-size: pte(20px, $fz);
                        font-weight: bold;
                        margin: 0;
                    }

                    &-place {
                        font-family: $ff1;
                        color: $light;
                        font-size: pte(16px, $fz);
                        font-style: italic;

                        i {
                            margin-right: ptr(8px, $fz);
                        }
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILE : #{-xxs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {}

/* ----------------------------------------------------- */
/* MOBILE : #{-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
    section {
        &.events {
            .events-home {
                .events-items {
                    .event-item-container {
                        margin: 0;
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* TABLET : #{-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    section {
        &.events {
            .events-home {
                .events-items {
                    .event-item-container {
                        padding: .25rem;
                    }
                }

                .filters {
                    margin-top: 2rem;
                }

                &-filters {
                    @include flex(flex, row, wrap, flex-start, flex-start);

                    .events-filter {
                        &:not(.all_events) {
                            display: inline-block;
                        }

                        &.all_events {
                            margin-top: 0;
                        }
                    }

                    .btn {
                        margin: 0 5px 5px 0;
                    }
                }
            }

        }
    }
}

/* ----------------------------------------------------- */
/* TABLET PORTRAIT : #{-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    section {
        &.events {
            .events-home-filters {
                @include flex(flex, row, wrap, flex-start, flex-end);

                height: 100%;
            }
            
            .events-home {
                .events-items {
                    .event-item-image {
                        height: pte(200px, $fz_md);

                        img {
                            width: 100%;
                            height: 100%;
                            @include objfit();
                        }
                    }
                }
            }
        }

    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
    section {
        &.events {
            .events-home {
                padding: 0;

                .section_title {
                    font-size: ptr(68px, $fz_lg);
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{-xl} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

}
