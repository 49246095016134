/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TEMOIGNAGES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* HOME - TEMOIGNAGE */
/* ----------------------------------------------------- */
.temoignages {
    background-color: $col5;
    padding: 2rem;
    background-image: url($imagePath + 'pattern_temoignage.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 40%;
    margin: 2rem 0 0 0;

    &__title {
        position: relative;
        color: $light;
        font-weight: bold;
        font-size: ptr(28px, $fz);
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
        text-align: center;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            width: ptr(40px, $fz);;
            height: 1px;
            background-color: $light;
            -webkit-transform: translate(-50%,0);
            -ms-transform: translate(-50%,0);
            -o-transform: translate(-50%,0);
            transform: translate(-50%,0);
        }
    }

    &__slider {
        .item {
            &:nth-child(2),
            &:nth-child(3) {
                display: none;
            }

            &.temoignage {
                .item {
                    &__header {
                        text-align: center;
                        margin-bottom: 1rem;
                    }

                    &__avatar {
                        text-align: center;
                        margin-bottom: .5rem;
                    }

                    &__title {
                        > p {
                            text-align: center;
                            font-weight: bold;
                            color: $dark;
                            margin: 0;
                        }
                    }

                    &__author {
                        color: $dark;
                        text-align: center;
                    }

                    &__text {
                        p {
                            font-family: $ff2;
                            font-size: ptr(12px, $fz);
                            font-style: italic;
                            text-align: justify;
                        }
                    } 
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    
    section {
        .temoignages {
            &__slider {
                .item {
                    &.temoignage {
                        .item {
                            &__header {
                                @include flex(flex, row, nowrap, flex-start, center);
                            }

                            &__avatar {
                                margin-right: 1rem;
                            }

                            &__title,
                            &__author {
                                text-align: left;
                            }

                            &__text {
                                p {
                                    text-align: left;
                                    line-height: 1.2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {


}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    section {
        .temoignages {
            &__slider {
                .item {
                    &.temoignage {
                        .item {
                            &__text {
                                p {
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    

}

