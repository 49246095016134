/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* CONTACT */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

section {
    .site-contact-header {
        padding: 20px;
        @include flex(flex, column, nowrap, center, center);
        h1 {
            text-align: center;
            font-size: 2rem;
            font-weight: normal;
            line-height: 40px;
            color: $col2;
            margin-top: pte(20px, $fz);
            span {
                font-family: $ff2;
                font-size: 2.2rem;
                display: block;
            }
        }
    }

    .site-contact-form {
        form {
            @include flex(flex, column, nowrap, center, center);
        }
        &_left, &_right {
            @include flex(flex, column, nowrap, space-between, center);
            padding: 10px 0;
            width: 100%;
            input, textarea, .g-recaptcha{
                margin-bottom: 20px;
            }
        }
        &_actions {
            @include flex(flex, column, nowrap, space-between, center);
            width: 100%;
            min-height: 150px;
            input {
                width: 100%;
            }
            &_protection {
                @include flex(flex, row, nowrap, space-between, center);
                width: 100%;
                margin-bottom: 20px;
                #protection {
                    max-width: 50px;
                }
                label {
                    font-size: 0.8rem;
                }
            }
            .g-recaptcha {
                margin-bottom: 20px;
            }
        }
    }

    .site-contact-separator{
        @include flex(flex, column, nowrap, space-between, center);
        background-color: $col1;
        color: $light;
        height: 200px;
        img {
            width: 100%;
            height: 100px;
            object-fit: cover;
            object-position: center;
        }
        h2 {
            @include flex(flex, column, nowrap, center, center);
            padding: 0 pte(40px, $fz);
            width: 100%;
            height: 100px;
            color: $light;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            font-weight: normal;

            span {
                display: block;
                font-weight: bold;
                font-family: $ff2;
                font-size: 1.9rem;
            }
        }
    }

    .site-contact-contenu {
        h2 {
            @include flex(flex, column, nowrap, center, center);
            padding: 0 pte(40px, $fz);
            width: 100%;
            height: 100px;
            color: $col2;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            font-weight: normal;

            span {
                display: block;
                font-weight: bold;
                font-family: $ff2;
                font-size: 1.9rem;
            }
        }
        &_texte {
            @include flex(flex, column, nowrap, center, center);
            padding: 0 pte(40px, $fz);
            width: 100%;
            &_left {
                border-bottom: 1px solid $col1;
            }
            &_left,&_right {
                width: 100%;
                padding: pte(40px, $fz) 0;
            }
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    section {
        .site-contact-separator {
            @include flex(flex, row, nowrap, space-between, center);
            height: 100px;
            img, h2 {
                width: 50%;
            }
            h2 {
                @include flex(flex, column, nowrap, center, flex-start);
            }
        }
        .site-contact-contenu {
            &_texte {
                @include flex(flex, row, nowrap, space-between, flex-start);
                width: 100%;
                &_left,&_right {
                    width: 45%;
                    border-bottom: none;
                }
            }
        }
    }
}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    section {
        .site-contact-form {
            form {
                @include flex(flex, row, wrap, space-between, self-start);
            }
            &_left, &_right {
                @include flex(flex, column, nowrap, space-between, center);
                width: 49%;
                height: 180px;
                align-self: stretch;
                input, textarea, .g-recaptcha{
                    margin-bottom: 0;
                }
                label {
                    display: none;
                }
                textarea {
                    height: 100%;
                }
            }
            &_actions {
                @include flex(flex, row, wrap, space-between, flex-end);
                width: 49%;
                margin-left: auto;
                input {
                    width: inherit;
                }
            }
        }
    }
}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
    section {
        .site-contact-form {
            &_actions {
                .g-recaptcha {
                    margin-bottom: 0;
                }
            }
        }
    }
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
}
