/* ----------------------------------------------------- */
/* STYLES GENERAUX */
/* ----------------------------------------------------- */
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"] {
    display: inline-block;
    width: 100%;
    color: $col1;
    background-color: $light;
    font-family: $ff1;
    font-size: 1em;
    font-weight: 500;
    text-transform: none;
    -webkit-appearance: none;
    border: 1px solid #979797;
    border-radius: $bdrs;
    padding: ptr(12px, $fz);
    margin: 0;
}

select {
    display: inline-block;
    width: 100%;
    color: $col1;
    background-color: $light;
    font-family: $ff1;
    font-size: 1em;
    font-weight: 500;
    text-transform: none;
    -webkit-appearance: none;
    border: 1px solid #979797;
    border-radius: $bdrs;
    padding: ptr(12px, $fz);
    margin: 0;

    option {
        color: $dark;
    }
}

.select_container {
    position: relative;
    font-family: $fficon;
    margin-bottom: 1rem;
    
    &:after {
        content: "\ea3e";
        position: absolute;
        font-size: ptr(12px, $fz);
        top: 50%;
        right: 1rem;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }

    select {
        margin: 0;
    }
}

input[type="radio"] {
    width: pte(24px, $fz) !important;
    height: pte(24px, $fz) !important;
    margin: 0 pte(10px, $fz) 0 0;

    // -webkit-appearance: none
}

select {
    option {
        color: $col3;
    }
}

label {
    color: $col1;
    font-family: $ff1;
    font-size: pte(16px, $fz);
    line-height: pte(19px, $fz);
    font-weight: 600;
    text-align: center;
    text-transform: none;
}

input {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        color: $col3;
        font-family: $ff1;
        font-size: pte(16px, $fz);
        line-height: pte(19px, $fz);
        font-weight: 600;
        text-align: center;
        text-transform: none;
    }
}

textarea {
    resize: vertical;
}

/* ----------------------------------------------------- */
/* MOTEURS DE RECHERCHE DE POSTS */
/* ----------------------------------------------------- */
.form-container {
    text-align: center;
    margin-bottom: 40px;

    form {
        display: inline-block;
        text-align: center;
        padding: 15px 50px;
        background-color: $light;
        border-radius: 50px;

        p {
            display: inline-block;
            vertical-align: baseline;
        }

        select {
            line-height: 60px;
            height: 50px;
            background: white;
            padding: 10px;
            font-size: 1em;
        }

        input[type='text'] {
            line-height: 60px;
            height: 60px;
            background: white;
            padding: 10px;
            font-size: 1em;
            border-radius: 0;
            border-color: $col2;
            color: $col2;
        }

        textarea {
            border-color: $col2;
        }
        ::-webkit-input-placeholder { /* WebKit browsers */
            text-transform: uppercase;
            color: $col2;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            text-transform: uppercase;
            color: $col2;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            text-transform: uppercase;
            color: $col2;
        }
        :-ms-input-placeholder { /* Internet Explorer 10+ */
            text-transform: uppercase;
            color: $col2;
        }
        ::placeholder { /* Recent browsers */
            text-transform: uppercase;
            color: $col2;
        }
    }
}

.acf-form-submit {
    text-align: right;
}

/* ----------------------------------------------------- */
/* MOTEURS DE RECHERCHE SIT / AUTRES */
/* ----------------------------------------------------- */
.moteur-recherche {
    display: block;
    position: relative;
    z-index: 5;

    .form-field-row {
        display: block;
        margin: 0 0 0.25em 0;

        .form-field:last-child {
            margin-right: 0;
        }
    }

    .form-field {
        display: inline-block;
        vertical-align: top;

        label {
            font-size: 0.75em;
            color: $light;
        }

        input[type="text"] {
            background-color: rgba($light, 0.6);
        }

        &.form-field-submit {
            display: block;
            text-align: center;
            margin: 1em 0;
        }
    }

    &.moteur-hebergements {
        text-align: center;
    }
}

/* ----------------------------------------------------- */
/* SURCHARGE WPFORMS */
/* ----------------------------------------------------- */

div.wpforms-container-full {
    .wpforms-form {
        .wpforms-field-label {
            color: $col1 !important;
            font-size: 1em !important;
        }

        .wpforms-field {
            overflow: hidden;
        }

        button[type=submit] {
            @extend .btn;

            @extend .btn-default;
        }
    }
}

.wpforms-container {
    .wpforms-field-label {
        font-weight: normal;
    }

    .wpforms-field-label-inline {
        font-size: 1em;
    }
}

.wpforms-label-hide,
.wpforms-sublabel-hide {
    display: none;
}

.wpforms-field-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.wpforms-field {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 0;

    &.half {
        flex-basis: 48%;

        &:last-child {
            margin-left: 4%;
        }
    }
}

/* ----------------------------------------------------- */
/* Contact Form 7 */
/* ----------------------------------------------------- */
.form {

    &-group {
        @include flex(flex, row, wrap, space-between, flex-start);
    }

    &-field {
        flex-basis: 100%;
        margin-bottom: 1rem;

        label {
            color: $col2;
            padding-bottom: .5rem;
        }

        &.form-half {
            flex-basis: 50%;
            // flex-basis: 100%;

            &:first-child {
                padding-right: .5rem;
            }

            &:last-child {
                padding-left: .5rem;
            }
        }

        &.form-two-thirds {
            flex-basis: calc(100% / 1.5);
            // flex-basis: 100%;
            padding-right: .5rem;
        }

        &.form-one-third {
            flex-basis: calc(100% / 3);
            // flex-basis: 100%;
            padding-left: .5rem;
        }

        &.form-submit {
            text-align: center;
        }

    }

}

/* ----------------------------------------------------- */
/* CONTACT FORM 7 */
/* ----------------------------------------------------- */
.wpcf7-form {
    &.sent {
        &:after {
            content: "";
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9998;
            background-color: rgba($light, .85);
        }
    }
}

.wpcf7-response-output {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 9999;
    padding: 1rem;

    &.wpcf7-mail-sent-ok {
        background-color: #398f14;
        color: $light;
        border-radius: $bdrs;
    }

}

/* ----------------------------------------------------- */
/* RECAPTCHA */
/* ----------------------------------------------------- */
.grecaptcha-badge {
    visibility: hidden !important;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    .form-field {
        margin: 0;
    }

    .select_container {
        margin: 0;
    }

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    

}

