.search-content {
  article {
    > a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $base-padding;
      border-bottom: 1px solid $col1;
      margin-bottom: $base-margin;

      .post-image {
        flex-basis: 20%;
      }

      .post-title {
        flex-basis: 80%;
        padding-left: $base-padding;
      }
    }
  }
}
