/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* STATIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* HOME */
/* ----------------------------------------------------- */
section {
	.partenaires {
		.partenaire {
			.item {
				&.station {
					.item {
						&__content {
							padding: 1rem;
							background-color: $col3;
						}

						&__text {
							p {
								font-size: ptr(11px, $fz);
								color: $light;
							}
						}

						&__place {
							@include flex(flex, row, nowrap, space-between, center);
						}

						&__town {
							font-size: ptr(11px, $fz);
							margin: 0;

							i {
								color: $col5;
								margin-right: .5rem;
							}
							span {
								color: $light;
								text-transform: uppercase;
							}
						}

						&__reduction {
							font-size: ptr(11px, $fz);
							padding: .25rem;
							background-color: $col5;
							color: $light;
							margin: 0;
						}
					}
				}
			}
		}
	}
}