/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* VARIABLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
@charset "utf-8";

/* ----------------------------------------------------- */
/* Couleurs */
/* ----------------------------------------------------- */
$light: #ffffff;
$dark: #000000;

/* Specifiques au site */
/* ----------------------------------------------------- */
$col1: #ae5841; /* brun moyen */
$col2: #8b3e2c; /* brun fonce */
$col3: #6A4324; /* brun clair */
$col4: #817272; /* gris clair */
$col5: #E5BD25; /* jaune */
$col6: #2E2E2E; /* gris foncé */
$col7: #CFCFCF; /* gris clair */
$col1-facebook: #4267B2;
$col2-facebook: #8b9dc3;

/* Declinaisons claires et foncees */
$col1-darker: darken($col1, 10%);
$col1-dark: darken($col1, 5%);
$col1-light: lighten($col1, 5%);
$col1-lighter: lighten($col1, 10%);

$col2-darker: darken($col2, 10%);
$col2-dark: darken($col2, 5%);
$col2-light: lighten($col2, 5%);
$col2-lighter: lighten($col2, 10%);

$col3-darker: darken($col3, 10%);
$col3-dark: darken($col3, 5%);
$col3-light: lighten($col3, 5%);
$col3-lighter: lighten($col3, 10%);

$col4-darker: darken($col4, 10%);
$col4-dark: darken($col4, 5%);
$col4-light: lighten($col4, 5%);
$col4-lighter: lighten($col4, 10%);

/* Correspondances couleurs -> texte */
/* ----------------------------------------------------- */
$colh1: $col1;
$colh2: $col1;
$colh3: $col1;
$colh4: $col1;
$colh5: $col1;
$colh6: $col1;

$coltext: $col2;
$collist: $col6;

$collink: $col3;
$collink_h: $col5;

/* ----------------------------------------------------- */
/* FONTS */
/* ----------------------------------------------------- */
$ff1: Playfair, Arial, sans-serif;
$ff2: BreadyAlternatesDemo, Arial, sans-serif;
$ff3: Cochin, Times, serif;
$ff4: Roboto, Arial, serif;

$fficon: "icomoon";
$ffa: "FontAwesome";

/* ----------------------------------------------------- */
/* FONT-SIZES */
/* ----------------------------------------------------- */
$fz: 16px;
$fz_xxs: 16px;
$fz_xs: 16px;
$fz_sm: 16px;
$fz_md: 16px;
$fz_lg: 16px;
$fz_xl: 16px;

/* ----------------------------------------------------- */
/* TRANSITIONS */
/* ----------------------------------------------------- */
$trans: all 0.4s ease-out;
$cubictrans: cubic-bezier(0.12, 0.9, 0.79, 1);
$cubic: all 1.4s $cubictrans;

$cubic2trans: cubic-bezier(0.14, 0.66, 0.28, 0.93);
$cubic2: all 0.2s $cubic2trans;

/* ----------------------------------------------------- */
/* BASES PADDING & MARGIN */
/* ----------------------------------------------------- */
$base-padding: 16px;
$base-margin: 20px;

/* ----------------------------------------------------- */
/* BORDER RADIUS */
/* ----------------------------------------------------- */
$bdrs: 0px;

/* ----------------------------------------------------- */
/* CONTAINERS */
/* ----------------------------------------------------- */
$container-width: 1540px;

/* ----------------------------------------------------- */
/* HEADER */
/* ----------------------------------------------------- */

$hdr-h: 60px;
$hdr-h_xs: 60px;
$hdr-h_sm: 60px;
$hdr-h_md: 60px;
$hdr-h_lg: 60px;
$hdr-reduced-h: 60px;

/* ----------------------------------------------------- */
/* SIDEBAR */
/* ----------------------------------------------------- */

$asd-h: 78px;
$asd-h-xs: 78px;

/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */

$ftr_bg: $col1;
$ftr_bg-dark: darken($ftr_bg, 10%);

/* ----------------------------------------------------- */
/* BREAKPOINTS */
/* ----------------------------------------------------- */
$screen-xxs: 480px;
$screen-xs: 640px;
$screen-sm: 769px;
$screen-md: 990px;
$screen-lg: 1300px;
$screen-xl: 1540px;

// $themePath: "/novtek/www/content/themes/novtek/";
$themePath: "/lesbellescomtoises/";
$imagePath: $themePath + "assets/images/";
$fontPath: $themePath + "assets/fonts/";

/* ----------------------------------------------------- */
/* HEXAGONE */
/* ----------------------------------------------------- */
$ratio: 1.1258;

/* ----------------------------------------------------- */
/* EASING */
/* ----------------------------------------------------- */
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
