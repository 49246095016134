.wsp-pages-list {
  > li {
    > .children {
      margin-left: 2em;

      > li {
        > a {
          color: $col1;

          &:hover {
            color: $col2;
          }
        }

        > .children {
          margin-left: 2em;
        }
      }
    }
  }
}

.wsp-posts-list {
  > li {
    .wsp-posts-list {
      margin-left: 2em;
    }
  }
}
