/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* CARTE INTERACTIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* Conteneur de map */
/* ----------------------------------------------------- */
#map {
  width: 100%;
}

/* ----------------------------------------------------- */
/* Surcharge du style Leaflet */
/* ----------------------------------------------------- */
.leaflet-left {
  .leaflet-control {
    margin-top: 10px !important;
  }
}

.leaflet-bar {
  a {
    background-color: $col1 !important;
    color: $light !important;

    &:hover {
      background-color: $col2 !important;
    }
  }
}

/* ----------------------------------------------------- */
/* Page map */
/* ----------------------------------------------------- */
.page-map {
  margin: pte(32px, $fz) 0 pte(40px, $fz) 0;

  .categories {
    &-title {
      font-size: 2em;
      background-color: $col2;
      color: $light;
      font-family: $ff1;
      text-align: left;
      padding: 1rem;
    }

    &-list {
      list-style: none;
      padding: 1em;
      background-color: rgba(54, 55, 89, 0.15);
      margin: 0;

      &-item {
        list-style: none;
        display: block;
      }
    }

    .category_filter {
      > i {
        display: inline-block;
        text-align: center;
        line-height: pte(40px, $fz);
        color: $light;
        margin-bottom: 1rem;
        width: pte(40px, $fz);
        height: pte(40px, $fz);
        border-radius: pte(40px, $fz);
        margin-right: 1rem;
        transition: $cubic2;
      }

      &.active {
        > i {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* GRILLE */
/* ----------------------------------------------------- */
.poi-grid {
  margin: 2rem 0;

  @include flex(flex, row, wrap, flex-start, stretch);

  .item-poi {
    flex-basis: 100%;
    margin-bottom: 1rem;

    > a {
      box-shadow: 0 0 6px rgba($dark, 0.1);

      @include flex(flex, row, nowrap, flex-start, stretch);
    }

    &-image {
      flex-basis: 25%;

      img {
        @include objFit;
      }
    }

    &-content {
      flex-basis: 75%;
      padding: 1rem;
    }

    &-title {
      p {
        font-family: $ff1;
        font-size: pte(18px, $fz);
        color: $col1;
        margin: 0;
      }
    }

    &-categories {
      ul {
        list-style: none;
        color: $dark;
        font-family: $ff1;
        font-size: pte(14px, $fz);
        font-weight: bold;
        padding: 0;
        margin: 0;
      }
    }
  }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {}

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
  .col_filters {
    margin: 2rem 0;
  }

  body.single-association {
    .post {
      &-image {
        img {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {}

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {}

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
  .page-map {
    .categories {
      &-list {
        padding: 2rem 1rem;
      }
    }
  }

  /* GRILLE */
  /* ----------------------------------------------------- */
  .poi-grid {
    .item-poi {
      flex-basis: 50%;
      padding-right: 1rem;

      > a {
        box-shadow: none;

        &:hover {
          box-shadow: 0 0 6px rgba($dark, 0.1);
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {}
