/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* STATIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* HOME */
/* ----------------------------------------------------- */
section {
	.partenaires {
		.partenaire {
			.item {
				&.offre {
					.item {
						&__content {
							padding: 1rem;
							background-color: $col3;
						}

						&__text {
							p {
								font-size: ptr(11px, $fz);
								color: $light;
							}
						}

						&__place {
							@include flex(flex, row, nowrap, space-between, center);
						}

						&__town {
							font-size: ptr(11px, $fz);
							margin: 0;

							i {
								color: $col5;
								margin-right: .5rem;
							}
							span {
								color: $light;
								text-transform: uppercase;
							}
						}

						&__reduction {
							font-size: ptr(11px, $fz);
							padding: .25rem;
							background-color: $col5;
							color: $light;
							margin: 0;
						}
					}
				}
			}
		}
	}
}

/* ----------------------------------------------------- */
/* TEMPLATE SLIDER */
/* ----------------------------------------------------- */
.offres {
	.items-slider {

		.item {
			&__inner {
				border: 1px solid $col7;
				padding: .5rem .5rem 2rem .5rem;
				position: relative;
			}

			&__image {
				position: relative;
			}

			&__reduction {
				position: absolute;
				top: 1rem;
				right: 0;

				padding: ptr(12px, $fz) ptr(8px, $fz);
				background-color: $col5;
				
				p {
					color: $light;
					margin: 0;

					strong {
						font-weight: bold;
					}
				}
			}

			&__logo {
				position: absolute;
				left: ptr(8px, $fz);
				bottom: ptr(8px, $fz);
				max-width: 30%;
			}

			&__title {
				padding: .5rem 0;
				font-weight: bold;
				color: $col1;
				font-size: ptr(18px, $fz);
			}

			&__place {
				p {
					margin: 0;

					i {
						color: $col5;
						margin-right: .25rem;
					}
				}
			}

			&__link {
				position: absolute;
				bottom: .5rem;
				right: 0.5rem;
			}

		}

	}

	.items__all {
		text-align: center;
		margin: 1rem 0;
	}
}


/* ----------------------------------------------------- */
/* TEMPLATE GRID */
/* ----------------------------------------------------- */
.offres_grid {

	&_title {
		.title {
			font-size: ptr(24px, $fz);
			color: $col3;
			font-weight: bold;
			text-align: left;
			padding: 0 .5rem;
		}
	}

	.items-grid {
		.item {
			cursor: pointer;
			

			&__inner {
				border: 1px solid $col7;
				padding: .5rem;
				position: relative;
			}

			&__image {
				position: relative;
			}

			&__reduction {
				position: absolute;
				top: 1rem;
				right: 0;
				z-index: 25;

				padding: ptr(12px, $fz) ptr(8px, $fz);
				background-color: $col5;
				
				p {
					color: $light;
					margin: 0;

					strong {
						font-weight: bold;
					}
				}
			}

			&__logo {
				position: absolute;
				left: ptr(8px, $fz);
				bottom: ptr(8px, $fz);
				max-width: 30%;
			}

			&__content {
				position: static;
				background: none;
				
			}

			&__title {
				padding: .5rem 0;
				font-weight: bold;
				color: $col1;
				background-color: transparent;
				-webkit-transform: none;
				-moz-transform: none;
				-ms-transform: none;
				-o-transform: none;
				transform: none;
				
				
				font-size: ptr(18px, $fz);
			}

			&__place {
				p {
					margin: 0;

					i {
						color: $col5;
						margin-right: .25rem;
					}
				}
			}

			&__link {
				position: static;
				width: 100%;
				text-align: right;
				
			}

			&:hover {
				.item {
					&__title {
						-webkit-transform: none;
						-moz-transform: none;
						-ms-transform: none;
						-o-transform: none;
						transform: none;
						
					}
				}
			}
		}

	}

}

/* ----------------------------------------------------- */
/* DETAIL D'UNE OFFRE */
/* ----------------------------------------------------- */
.offre__single {

	&_title {
		padding: 0 .5rem;

		h1 {
			font-size: ptr(24px, $fz);
			margin-bottom: 2rem;
		}
	}

	&_images {
		margin-bottom: 0;

		.slick-dots {
			bottom: 0;

			li {
				box-shadow: 0 0 4px rgba(0,0,0,0.4);

				button {
					background-color: $light;
				}
			}
		}
	}

	&_place {
		background-color: $col5;
		padding: 1rem;
		border: 1px solid darken($col5, 10%);
		border-bottom: none;
		

		p {
			margin: 0;
			text-transform: uppercase;
			font-weight: bold;
			
		}
	}

	&_details {
		padding: 1rem;
		border: 1px solid #979797;
		border-top: none;

		&_dates,
		&_reduction {
			p {
				@include flex(flex, row, nowrap, flex-start, center);
				color: $col3;

				i {
					font-size: ptr(24px, $fz);
				}

				span {
					padding-left: .5rem;
					line-height: 1;
				}
			}
		}

	}

	&_link {
		margin: 1rem 0;

		.btn {
			width: 100%;
		}
	}

}


/* ----------------------------------------------------- */
/* SECTION HIGHLIGHTS */
/* ----------------------------------------------------- */
.offres_highlights {

	&_title {
		.title {
			font-size: ptr(24px, $fz);
			color: $col3;
			font-weight: bold;
			text-align: left;
			padding: 0 .5rem;
		}
	}

	&.offres_grid {
		.items-grid {

			.item {
				&__inner {
					border: none;
 					position: relative;
 					padding: 0;
				}

				&__reduction {
					z-index: 25;
				}

				&__content {
					position: absolute;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 100%);
				}

				&__title {
					color: $light;
					background-color: transparent;
				}

				&__place {
					p {
						span {
							color: $light;
						}
					}
				}

				&:hover {
					.item__title {
						color: $light;
					}
				}

			}

		}
	}

}


/* ----------------------------------------------------- */
/* MOTEUR DE RECHERCHE */
/* ----------------------------------------------------- */
.offre {

	&__search {
		padding: 0;
		-webkit-transform: translate(0, -2.5rem);
		-moz-transform: translate(0, -2.5rem);
		-ms-transform: translate(0, -2.5rem);
		-o-transform: translate(0, -2.5rem);
		transform: translate(0, -2.5rem);

		&_container {
			background-color: $col5;
			padding: 2rem;
		}

		&_title {
			font-weight: bold;
			color: $light;
			font-size: ptr(24px, $fz);
			margin-bottom: 1rem;
		}

		&_form {

		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	.offres {
		.items__all {
			margin: 2rem 0;
		}

		&_grid {
			&_title {
				.title {
					padding: 0 1rem;
				}
			}
		}
	}


	.offres_highlights_title .title {
		padding: 0 1rem;
	}

	.offre__single {
		&_title {
			padding: 0 1rem;
		}
	}
}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {


}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.offre__single {
		&_title {
			h1 {
				width: calc(100% / 12 * 7);
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.offre {

		&__search {
			&_form {
				form {
					@include flex(flex, row, nowrap, flex-start, center);

					.offre__search_title,
					.form-group {
						flex-basis: 25%;
						padding: 0 .5rem;
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

