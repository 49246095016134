/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ANIMATIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* SCROLLDISC */
/* ----------------------------------------------------- */
@-webkit-keyframes scrollDisc {
  0% {
    top: -30%;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

@-moz-keyframes scrollDisc {
  0% {
    top: -30%;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

@-o-keyframes scrollDisc {
  0% {
    top: -30%;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

/* ----------------------------------------------------- */
/* ONDE */
/* ----------------------------------------------------- */
@-webkit-keyframes onde {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  10% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: scale(1.25);
  }
}

/* ----------------------------------------------------- */
/* BOUNCE */
/* ----------------------------------------------------- */
@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

/* ----------------------------------------------------- */
/* BALANCE */
/* ----------------------------------------------------- */
@keyframes balance {
  0%, 100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-20%);
  }

  75% {
    transform: translateX(20%);
  }
}

/* ----------------------------------------------------- */
/* BALANCE VERTICALE */
/* ----------------------------------------------------- */
@keyframes balancev {
  0%, 100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-20%);
  }

  75% {
    transform: translateY(20%);
  }
}

/* ----------------------------------------------------- */
/* PULSE */
/* ----------------------------------------------------- */
@keyframes pulse {
  0%, 100% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }
}

/* ----------------------------------------------------- */
/* WAVES */
/* ----------------------------------------------------- */
@keyframes waves {
  0% {
    transform: scale(1);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  80% {
    transform: scale(1.1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scale(1.1);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

/* ----------------------------------------------------- */
/* RESSORT */
/* ----------------------------------------------------- */
@keyframes ressort {
  0%, 100% {
    transform: translate(-25%, -50%);
  }

  50% {
    transform: translate(0%, -50%);
  }
}

/* ----------------------------------------------------- */
/* RESSORT VERTICAL */
/* ----------------------------------------------------- */
@keyframes ressortv {
  0%, 100% {
    transform: translateY(-25%);
  }

  50% {
    transform: translateY(0%);
  }
}

/* ----------------------------------------------------- */
/* BOUNCE */
/* ----------------------------------------------------- */
@keyframes elastic {
  0% {
    transform: scale(1.1);
    opacity: 1;
  }

  50% {
    transform: scale(1.4);
    opacity: 0.7;
  }

  60% {
    transform: scale(0.6);
    opacity: 1;
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

/* ----------------------------------------------------- */
/* FLOAT */
/* ----------------------------------------------------- */
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

/* ----------------------------------------------------- */
/* LDS ROLLER */
/* ----------------------------------------------------- */
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
