/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MODULES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.module {
  /* ----------------------------------------------------- */
  /* BRANCHEMENT DE PAGES */
  /* ----------------------------------------------------- */

  &.branchepage {
    /* GRILLE */
    /* ----------------------------------------------------- */
    .module-branchepage-grid {
      padding: pte(122px, $fz) 0 pte(158px, $fz) 0;

      .section_title {
        text-align: left;
      }

      .text {
        margin-top: pte(142px, $fz);

        p {
          font-size: pte(20px, $fz);
        }
      }

      .grid-item {
        margin-top: pte(38px, $fz);
        position: relative;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .image-container {
          width: 28vh;
          height: 28vh;
          border-radius: 100%;
          border: 1vh solid $col1;
          border-radius: 100%;
          position: relative;
          z-index: 2;
          margin: 0 auto;

          .img {
            overflow: hidden;
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }

          img {
            @include objfit;

            filter: grayscale(1);
            transform: scale(1);
            transition: $trans, transform 4s ease-out;
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: $col1;
            opacity: 0;
            transition: $trans;
            transform: scale(0.9);
          }
        }

        .grid-item-title {
          display: block;
          font-family: $ff1;
          font-size: pte(24px, $fz);
          margin-top: pte(38px, 24px);
          color: $dark;
          text-align: center;
          transition: $trans;
        }

        &:hover {
          .image-container {
            img {
              filter: grayscale(0);
              transform: scale(1.25);
            }

            &:before {
              animation: onde 2s ease-out infinite;
            }
          }

          .grid-item-title {
            color: $col1;
          }
        }
      }
    }

    /* Liste */
    /* ----------------------------------------------------- */
    .module-branchepage-list {
      padding: pte(76px, $fz) 0;

      .section_title {
        position: relative;

        .btn {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .items {
        .item {
          cursor: pointer;
          margin: pte(66px, $fz) 0 0 0;

          a {
            color: inherit;
          }
        }

        .item,
        .item a {
          .date {
            font-family: $ff1;
            font-weight: 600;
            color: $col3;
            font-size: pte(26px, $fz);
            text-align: left;
            position: relative;
            padding: 0 pte(7px, 26px) pte(10px, 26px) 0;

            span {
              position: relative;

              &:after {
                content: "";
                display: inline-block;
                transform-origin: 0 0;
                width: pte(44px, 26px);
                height: 2px;
                background-color: $col3;
                margin-left: pte(6px, 26px);
                -webkit-transition: transform $cubic;
                -moz-transition: transform $cubic;
                -ms-transition: transform $cubic;
                -o-transition: transform $cubic;
                transition: transform $cubic;
              }
            }
          }

          .extrait {
            p {
              font-family: $ff1;
              font-weight: normal;
              text-transform: none;
              font-style: normal;
              font-size: 1em;
              color: $coltext;
              -webkit-transition: $trans;
              -moz-transition: $trans;
              -ms-transition: $trans;
              -o-transition: $trans;
              transition: $trans;
            }
          }

          &:hover {
            .date {
              color: $col2;

              span {
                &:after {
                  -webkit-transform: scaleX(2);
                  -moz-transform: scaleX(2);
                  -ms-transform: scaleX(2);
                  -o-transform: scaleX(2);
                  transform: scaleX(2);
                }
              }
            }

            .extrait {
              p {
                color: $dark;
              }
            }
          }
        }
      }
    }

    /* EN CARTOUCHES */
    /* ----------------------------------------------------- */
    .module-branchepage-cartouches {
      position: relative;
      padding: 0 0 pte(76px, $fz) 0;

      .row.flex {
        flex-wrap: wrap;
        justify-content: center;
      }

      .page-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .page {
        position: relative;
        z-index: 2;
        flex-basis: calc(100% / 3);
        text-align: center;
      }

      /* (pattern) */
      .pattern-left {
        position: absolute;
        top: -40%;
        left: 0;
        width: auto;
        height: 30vh;
        max-height: pte(400px, $fz);
        text-align: left;
        overflow: hidden;

        #pattern-left {
          width: auto;
          height: 100%;

          path {
            fill: rgba($col1, 0.5);
          }
        }

        #pattern-left-strokes {
          width: auto;
          height: 100%;

          line {
            stroke: rgba($col3, 0.3);
            stroke-width: 2px;
            stroke-dasharray: 60px;
            stroke-dashoffset: 60px;
            transition: $cubic;

            html:not(.parallax) & {
              stroke-dashoffset: 0px;
            }
          }
        }
      }

      .pattern-right {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: 30vh;
        max-height: pte(400px, $fz);
        text-align: right;
        overflow: hidden;

        #pattern-right {
          width: auto;
          height: 100%;

          path {
            fill: rgba($col2, 0.5);
          }
        }

        #pattern-right-strokes {
          width: auto;
          height: 100%;

          line {
            stroke: rgba($col2, 0.3);
            stroke-width: 2px;
            stroke-dasharray: 60px;
            stroke-dashoffset: 60px;
            transition: $cubic;

            html:not(.parallax) & {
              stroke-dashoffset: 0px;
            }
          }
        }
      }

      .cartouches-item {
        overflow: hidden;
        display: inline-block;
        width: 18vw;
        height: 18vw;
        border-radius: 18vw;
        background: $dark;
        border: 1vh solid $col3;
        position: relative;
        margin: 0 pte(24px, $fz);

        .content {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: pte(80px, $fz) 0;

          > a {
            position: relative;
            z-index: 3;
          }
        }

        .icon {
          text-align: center;
          height: 60px;
          margin-bottom: 1em;

          img {
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        .title {
          margin-bottom: 0;

          h2 {
            color: $light;
            font-family: $ff1;
            font-size: pte(28px, $fz);
            text-align: center;
            margin-bottom: 0;
          }
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-color: $col3;
          opacity: 0;
          transition: $trans;
          transform: scale(0.9);
        }

        &:hover {
          &:before {
            animation: onde 2s ease-out infinite;
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {
  .module {
    &.branchepage {
      .module-branchepage {
        &-cartouches {
          .cartouches-item {
            .title {
              h2 {
                font-size: 1.75vw;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {
  .module {
    &.branchepage {
      .module-branchepage {
        &-grid {
          padding: 4vh 0;

          .grid-item {
            .image-container {
              width: 18vw;
              height: 18vw;
            }
          }

          .text {
            margin-top: 2vh;
          }
        }

        &-cartouches {
          .page {
            width: 50%;
          }

          .cartouches-item {
            width: 28vw;
            height: 28vw;
            border-radius: 28vw;

            .title {
              h2 {
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {
  .module {
    &.branchepage {
      .module-branchepage {
        &-grid {
          .grid-item {
            .image-container {
              width: 40vw;
              height: 40vw;
            }
          }
        }

        &-cartouches {
          .cartouches-item {
            width: 28vw;
            height: 28vw;
            border-radius: 28vw;
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {
  .module {
    &.branchepage {
      .module-branchepage {
        &-grid {
          .section_title {
            padding: 0;
          }

          .text {
            p {
              font-size: 1em;
            }
          }
        }

        &-cartouches {
          .page {
            flex-basis: 100%;
          }

          .cartouches-item {
            width: 60vw;
            height: 60vw;
            border-radius: 100%;

            .icon {
              height: 100px;
            }

            .title {
              h2 {
                font-size: 6vw;
                padding: 0 1em;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {}
