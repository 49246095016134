/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* POSTS.SCSS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* PAGE NEWS */
/* ----------------------------------------------------- */
body.single {
  h1 {
    color: $col1;
    font-family: $ff1;
  }

  .post {
    &-image {
      order: 2;

      img {
        margin-bottom: 1rem;
      }
    }

    &-content {
      order: 1;
    }
  }

  .single {
    &_title {}

    &_date {
      color: $col3;
      font-family: $ff1;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: uppercase;
      margin: 1rem 0;
    }

    &_categories {
      list-style: none;
      padding: 0;
      margin: 0 0 3rem 0;

      li {
        display: inline-block;
        vertical-align: top;
        padding: 4px;
        color: $light;
      }
    }

    &_content {
      p {
        color: $dark;
        font-family: $ff1;
      }
    }

    &_adresse {
      margin-top: 3rem;

      .place {
        &_name {
          color: $col1;
          font-family: $ff1;
          font-size: 1.25rem;
        }

        &_address {
          p {
            color: $dark;
            font-weight: bold;
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* SECTION NEWS */
/* ----------------------------------------------------- */
section.news-section {
  position: relative;
  z-index: 2;

  .news-item-container {
    /* Flexbox */
    /* ----------------------------------------------------- */
    html.flexbox & {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: stretch;

      .news-item {
        flex-basis: 50%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    /* NO Flexbox */
    /* ----------------------------------------------------- */
    html:not(.flexbox) & {
      .news-item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }
    }

    /* Items */
    /* ----------------------------------------------------- */
    .news-item {
      padding: 60px;

      /* Image */
      .image {
        position: relative;
        width: 100%;
        height: 280px;
        overflow: hidden;
        border-bottom: 10px solid $col2;
        padding: 35px;

        img {
          @include imgCenter;
        }
      }

      /* Content */
      .content {
        padding: 35px;

        .date {
          font-family: $ff1;
          font-weight: bold;
          font-size: 0.9375em;
          color: $col2;
          letter-spacing: 1.25em;
          padding-bottom: 40px;
        }

        .titre_news {
          font-family: $ff1;
          font-weight: bold;
          font-size: 1.375em;
          color: $col1;
          text-transform: uppercase;

          a {
            color: $col1;

            &:hover {
              color: $col2;
            }
          }
        }

        .extrait {
          font-family: $ff1;
          font-weight: normal;
          font-size: 0.9375em;
          color: $col2;

          strong,
          b {
            color: $col1;
          }
        }
      }

      /* LIen */
      .link {
        padding: 35px;
      }
    }
  }
}

/* ----------------------------------------------------- */
/* THEMES */
/* ----------------------------------------------------- */
.theme {
  position: absolute;
  bottom: -15px;
  left: 0;
  background: $col1;
  color: $light;
  font-family: $ff1;
  text-transform: uppercase;
  font-weight: 500;
  padding: 6px 0px;
  z-index: 2;
  height: 30px;

  // &::after
  .triangle {
    position: absolute;
    top: 0;
    right: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent $col1;
  }

  span {
    padding: 6px 20px;
  }
}

/* ----------------------------------------------------- */
/* ACTUS */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* ACTUALITES SUR LA HOMEPAGE */
/* ----------------------------------------------------- */
.actualites-container {
  // On verifie que le browser connait FLEX
  html.flexbox & {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: stretch;

    .container_actu {
      margin: 0;

      .bloc_item_actu {
        height: 100%;

        figure {
          height: 100%;
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* ACTUALITES DANS LA PAGE ACTUALITES */
/* ----------------------------------------------------- */
.page_actualites,
.page_search {
  // On verifie que le browser connait FLEX
  html.flexbox & {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &.container_actu {
    margin: 0;

    .bloc_item_actu {
      flex-basis: 47%;
      width: 50%;

      .item_actu {
        height: 100%;
      }
    }
  }
}

/* ----------------------------------------------------- */
/* TYPOLOGIES */
/* ----------------------------------------------------- */
.typologies-results {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;

  .typologie-result {
    flex-basis: 24%;

    .post-image {
      width: 100%;
      position: relative;
      height: 208px;
      overflow: hidden;

      img {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .title {
      margin: 1em 0px;
      min-height: 76px;
    }

    .post-link {
      text-align: right;
      margin: 1em 0px;
    }
  }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {}

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
  body {
    &.single {
      .post {
        &-image {
          @include flex(flex, row, wrap, flex-start, stretch);

          img {
            flex-basis: 50%;
            max-width: 49%;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
  body {
    &.single {
      .post {
        &-image {
          order: 1;

          @include flex(flex, row, wrap, flex-start, stretch);

          img {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: 1rem;
          }
        }

        &-content {
          order: 2;
        }
      }
    }
  }
}

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {}

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {}

/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {}
