/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* OFFRES D'EMPLOI */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.items.items-offre_emploi {

	/* ----------------------------------------------------- */
	/* LIST */
	/* ----------------------------------------------------- */
	&.items-list {

		.item {
			border: 2px solid $col1;
			margin: 0 0 2rem 0;
			-webkit-transition: $trans;
			-moz-transition: $trans;
			-ms-transition: $trans;
			-o-transition: $trans;
			transition: $trans;

			&__inner {
				padding: 1rem;
				position: relative;
				box-shadow: none;
				border: none;
				
			}

			&__title {
				font-weight: 800;
				color: $col2;
				margin-bottom: .5rem;
				font-size: 1.5rem;
			}


			&__details {
				@include flex(flex, row, wrap, flex-start, center);

				p {
					font-size: 1.25rem;
					flex-basis: 100%;

					&.ville {
						color: $dark;
					}

					&.contrat {
						color: $col1;
						text-transform: uppercase;
					}
				}
			}

			&:hover {
				border-color: $col2;
			}

		}

	}


}

/* ----------------------------------------------------- */
/* SINGLE */
/* ----------------------------------------------------- */
body.single-offre_emploi {

	.item {

		&__content {
			padding-bottom: 2rem;
			border-bottom: 1px solid $col1;
			margin-bottom: 2rem;
		}

		&__details {
			padding: 2rem 0;
			border-bottom: 1px solid $col1;

			p {
				font-size: 1.25rem;

				i {
					font-size: 1.5rem;
					margin-right: 1rem;
				}
			}
		}

		&__more {
			padding: 2rem 0;
		}

		&__postuler {
			padding-top: 2rem;
		}

		&__nav {
			padding: 2rem 0;
			border-top: 1px solid $col1;
			@include flex(flex, row, nowrap, space-between, center);
	
			a {
				font-weight: 600;
				color: $col1;
				flex-basis: 50%;

				span {
					display: none;
				}

				&:first-child {
					text-align: left;
				}

				&:last-child {
					text-align: right;
				}

				&:hover {
					color: $col2;
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	body.single-offre_emploi {
		.item {
			&__nav {
				a {
					span {
						display: inline-block;
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.items.items-offre_emploi {

		/* ----------------------------------------------------- */
		/* LIST */
		/* ----------------------------------------------------- */
		&.items-list {

			.item {
				&__details {
					@include flex(flex, row, nowrap, flex-start, center);

					p {
						flex-basis: 50%;
					}
				}
			}
		}

	}

	/* ----------------------------------------------------- */
	/* SINGLE */
	/* ----------------------------------------------------- */
	body.single-offre_emploi {
		.item {
			@include flex(flex, row, wrap, flex-start, stretch);

			&__content {
				flex-basis: 60%;
				margin-bottom: 0;
				border-right: 1px solid $col1;
				padding-right: 1rem;
			}

			&__details {
				flex-basis: 40%;
				padding-left: 1rem;

				p {
					font-size: 1rem;
				}
			}

			&__more {
				flex-basis: 100%;
			}
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.items.items-offre_emploi {

		/* ----------------------------------------------------- */
		/* LIST */
		/* ----------------------------------------------------- */
		&.items-list {

			.item {
				button {
					position: absolute;
					right: 1rem;
					top: 1rem;
				}
			}

		}

	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	body.single-offre_emploi {
		.item {
			&__details {
				padding-left: 2rem;
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

