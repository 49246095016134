/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MAIN */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.site-main {
  // margin-top: pte(40px, $fz);
  padding: 0;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
  .site-main {
    margin-top: 0;
  }
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
  .site-main {
    padding: 0;
  }
}

/* ----------------------------------------------------- */
/* TABLET PORTRAIT : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
  .site-main {
    padding: 0;
  }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {}
