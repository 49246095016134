/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* HOME PAGE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
body.home {
	.site-home-contenu {
		padding: 50px 0;
		background-image: url("../images/taches_vache_coin_droite.png");
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 50%;
		&.bg-left {
			background-image: url("../images/taches_vache_coin_gauche.png");
			background-position: left bottom;
			background-size: 55%;
		}
		.container {
			@include flex(flex, column, nowrap, center, center);
		}
		&_image {
			max-width: 500px;
			display: flex;
			justify-content: center;
			video {
				height: 500px;
				max-width: 90%;
			}
		}
		&_texte {
			text-align: center;
			padding: 10px 10px 40px;
			max-width: 500px;
			h2 {
				color: $col2;
				font-family: $ff2;
				margin-bottom: 10px;
				@include flex(flex, column, nowrap, center, center);
				span {
					font-family: $ff2;
				}
			}
			p {
				color: $col2;
			}
		}

		&_slider {
			width: 350px;

			.slider-element{
				@include flex(flex, column, nowrap, center, center);
				max-width: 350px;
				img {
					border: 10px solid $col2;
					margin-bottom: 20px;
				}
				p {
					color: $col2;
					margin-bottom: 5px;
				}
				a {
					font-weight: bold;
					margin-bottom: 10px;
					&.link-icon {
						@include flex(flex, row, nowrap, center, center);
						border-radius: 50%;
						border: 1px solid $col1-facebook;
						background-color: $col1-facebook;
						color: $light;
						text-align: center;
						font-size: ptr(16px, $fz_xs);
						width: calc(#{$hdr_h_xs} - 1rem);
						height: calc(#{$hdr_h_xs} - 1rem);
						font-weight: normal;
						margin-top: 10px;
					}
				}
				h3 {
					font-weight: normal;
					font-size: 1.2rem;
					span {
						font-weight: bold;
						font-style: italic;
					}
					margin-bottom: 20px;
				}
			}
		}
	}
	.site-home-logos {
		background-color: $col1;
		padding: 3rem 0;
		&_logos {
			@include flex(flex, column, nowrap, center, center);
			a {
				opacity: 0.5;
				max-width: 250px;
				&:hover{
					opacity: 1;
				}
			}
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {
}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
	body.home {
		.site-home-contenu {
			background-size: 20%;
			&.bg-left {
				background-size: 25%;
			}
			.container {
				@include flex(flex, row, nowrap, space-around, center);
			}
		}
		.site-home-logos {
			&_logos {
				@include flex(flex, row, nowrap, space-around, center);
			}
		}
	}
}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
	body.home {
		.site-home-contenu {
			background-size: 10%;
			&.bg-left {
				background-size: 15%;
			}
		}
	}
}

