/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* STATIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* HOME */
/* ----------------------------------------------------- */
section {
    .partenaires {
        .partenaire {
            .item {
                &.news {
                    .item {
                        &__content {
                            padding: 1rem;
                            background-color: $col3;
                        }

                        &__text {
                            p {
                                font-size: ptr(11px, $fz);
                                color: $light;
                            }
                        }

                        &__place {
                            @include flex(flex, row, nowrap, space-between, center);
                        }

                        &__town {
                            font-size: ptr(11px, $fz);
                            margin: 0;

                            i {
                                color: $col5;
                                margin-right: .5rem;
                            }
                            span {
                                color: $light;
                                text-transform: uppercase;
                            }
                        }

                        &__reduction {
                            font-size: ptr(11px, $fz);
                            padding: .25rem;
                            background-color: $col5;
                            color: $light;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}


/* ----------------------------------------------------- */
/* TEMPLATE SLIDER */
/* ----------------------------------------------------- */
.news {

    .section_title {
        color: $col5;
        text-transform: none;
    }

    .items-slider {

        .item {
            &__inner {
                position: relative;
                border-bottom: 5px solid $col5;
            }

            &__image {
                position: relative;
            }

            &__date {
                position: absolute;
                left: ptr(8px, $fz);
                bottom: 0;
                padding: .5rem;
                background-color: $col1;

                p {
                    color: $light;
                    margin: 0;
                    font-weight: bold;
                    
                }
            }

            &__content {
                padding: .5rem;
            }

            &__title {
                padding: .5rem 0;
                font-weight: bold;
                color: $col3;
                font-size: ptr(18px, $fz);
            }

            &__excerpt {
                display: block;

                p {
                    margin: 0;
                    color: $col1;

                    i {
                        color: $col5;
                        margin-right: .25rem;
                    }
                }
            }

            &__link {
                text-align: right;
            }

        }

    }

    .items__all {
        text-align: center;
        margin: 1rem 0;
    }
}