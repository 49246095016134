/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SOMMAIRE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.sommaire {
  position: relative;
  margin: 1em 0;
  text-align: center;

  .sommaire-page {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 4);
    height: 100px;
    padding: 1em;

    .sommaire-inner {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .sommaire-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
      }
    }

    .sommaire-title {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($col1, 0.75);
      text-align: center;

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
        opacity: 0.75;
        transition: $trans;
        color: $light;
        font-size: 3em;

        a {
          color: $light;
        }
      }
    }

    &:hover {
      .sommaire-title {
        h2 {
          opacity: 1;
        }
      }
    }
  }
}
