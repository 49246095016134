// SIMPLE GRID - SASS/SCSS

// utility

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.justify {
	text-align: justify;
}

.flex {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.grid {
	display: -webkit-grid;
	display: -moz-grid;
	display: -ms-grid;
	display: -o-grid;
	display: grid;
}

// grid

$width: 100%;
$gutter: 0%;
$nbcols: 12;

.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.row {
	position: relative;
	width: 100%;

	[class*="col-"] {
		float: left;
		min-height: 0;

		&.col-no-margin {
			margin: 0;
		}

		&.no-padding {
			padding: 0 !important;
		}

		&.col-0 {
			width: 0;
			flex-basis: 0;
			padding: 0;
		}

		@for $i from 1 through $nbcols {
			&.col-#{$i} {
				width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
				flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
				padding: 0rem .5rem;
			}
		}
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	&.flex {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;

		[class*="col-"] {
			float: none !important;
		}
	}
}

.hidden {
	display: none;
}

.hidden-xs,
.hidden-xxs,
.hidden-sm,
.hidden-md,
.hidden-lg,
.hidden-xl {
	display: block;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-xxs) {

	.hidden {
		display: block;
	}

	.hidden-xxs {
		display: none;
	}

	.container {
		width: 100%;
		padding: 0;
	}

	.row {
		[class*="col-"] {
			&.col-0-xxs {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-xxs {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-xxs {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-xxs {
						display: block;
					}
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-xs) {

	.row {
		[class*="col-"] {
			&.col-0-xs {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-xs {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-xs {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-xs {
						display: block;
					}
				}
			}
		}
	}

	.hidden-xs {
		display: none;
	}

	.container {
		width: 100%;
		padding: 0;
	}
}

/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-sm) {
	
	.row {
		[class*="col-"] {
			&.col-0-sm {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-sm {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-sm {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-sm {
						display: block;
					}
				}
			}
		}
	}

	.hidden-xs {
		display: block;
	}

	.hidden-sm {
		display: none;
	}

	.container {
		width: 100%;
		padding: 0;
	}
}

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-md) {
	
	.row {
		[class*="col-"] {
			&.col-0-md {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}

		&.flex {
			flex-wrap: wrap;
			align-items: stretch;
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-md {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-md {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-md {
						display: block;
					}
				}
			}
		}
	}

	.hidden-xs,
	.hidden-sm {
		display: block;
	}

	.hidden-md {
		display: none;
	}

	.container {
		width: 100%;
	}
}

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-lg) {

	.row {
		[class*="col-"] {
			&.col-0-lg {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}

		&.flex {
			flex-wrap: wrap;
			align-items: stretch;
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-lg {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-lg {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-lg {
						display: block;
					}
				}
			}
		}
	}

	.hidden-xs,
	.hidden-sm,
	.hidden-md {
		display: block;
	}

	.hidden-lg {
		display: none;
	}

	.container {
		width: 100%;
		max-width: $container-width;
	}
}

/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media only screen and (min-width: $screen-xl) {
	
	.row {
		[class*="col-"] {
			&.col-0-xl {
				width: 0;
				flex-basis: 0;
				padding: 0;
			}
		}

		&.flex {
			flex-wrap: wrap;
			align-items: stretch;
		}
	}

	@for $i from 1 through $nbcols {
		.row {
			[class*="col-"] {
				&.col-#{$i}-xl {
					width: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					flex-basis: $width / $nbcols * $i - $gutter * ($nbcols - $i) / $nbcols;
					padding: 0rem 1rem;
				}
			}

			&:not(.flex) {
				[class*="col-"] {
					&.col-#{$i}-xl {
						display: block;
					}
				}
			}

			&.flex {
				[class*="col-"] {
					&.col-#{$i}-xl {
						display: block;
					}
				}
			}
		}
	}

	.hidden-xs,
	.hidden-sm,
	.hidden-md,
	.hidden-lg {
		display: block;
	}

	.hidden-xl {
		display: none;
	}

	.container {
		max-width: $container-width;
		width: 100%;
	}
}
