/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* itemS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.items {

	/* ----------------------------------------------------- */
	/* GRID */
	/* ----------------------------------------------------- */
	&-grid {
		@include flex(flex, row, wrap, center, flex-start);
		margin-top: 2rem;

		.item {
			position: relative;
			padding: .5rem 0;
			flex-basis: 100%;
			flex-grow: 0;
			flex-shrink: 0;

			// margin-bottom: 1rem;

			&__inner {
				// margin: .5rem;
				position: relative;
			}

			&__image {
				height: 50vh;
				position: relative;
				
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($dark,0.5);
					-webkit-transition: $trans;
					-moz-transition: $trans;
					-ms-transition: $trans;
					-o-transition: $trans;
					transition: $trans;
					opacity: 0;
				}

				img {
					display: block;
					@include objfit();
				}
			}

			&__content {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 1rem;
				color: $light;
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.52) 100%);
				@include flex(flex, column, nowrap, flex-end, flex-start);
			}

			&__title {
				display: inline-block;
				text-align: left;
				font-weight: 500;
				color: $col1;
				text-transform: uppercase;
				-webkit-transform: translate(0, -1.5rem);
				-ms-transform: translate(0, -1.5rem);
				-o-transform: translate(0, -1.5rem);
				transform: translate(0, -1.5rem);
				-webkit-transition: $trans;
				-moz-transition: $trans;
				-ms-transition: $trans;
				-o-transition: $trans;
				transition: $trans;
				padding: 1rem;
				background-color: $light;

				h2 {
					font-family: $ff1;
					font-weight: bold;
					color: $light;
					font-size: pte(20px, $fz);
					margin-bottom: 1rem;
				}
			}

			&__excerpt {
				display: none;
				p {
					font-family: $ff1;
					font-weight: normal;
					color: $light;
					font-size: 1rem;
					margin: 0;
				}
			}

			&__media {
				video,
				iframe {
					width: 100%;
					height: auto;
				}
			}

			&__permalink {
				width: 100%;
				margin-top: 1rem;
				text-align: right;

				button {
					cursor: pointer;
				}
					
			}

			&__date {
				position: absolute;
				bottom: .5rem;

				p {
					font-family: $ff1;
					color: #A0A0A0;
					font-style: italic;
				}
			}

			/* Effets au hover */
			/* ----------------------------------------------------- */
			&:hover {
				.item {

					&__image {
						&:after {
							opacity: 1;
						}
					}

					&__title {
						-webkit-transform: translate(0, -3rem);
						-ms-transform: translate(0, -3rem);
						-o-transform: translate(0, -3rem);
						transform: translate(0, -3rem);
						color: $col2;
					}
				}
			}

		}

	}

	/* ----------------------------------------------------- */
	/* SLIDER */
	/* ----------------------------------------------------- */
	&-slider {
		width: 100%;

		.item {
			position: relative;

			flex-basis: 100%;
			flex-grow: 0;
			flex-shrink: 0;

			// margin-bottom: 1rem;
			&__inner {
				margin: .5rem;
				position: relative;
			}

			&__image {
				height: 34vh;

				> img {
					display: block;
					@include objfit();
				}
			}

			&__media {
				video,
				iframe {
					width: 100%;
					height: auto;
				}
			}

			&__content {
				
			}

			&__title {
				h2 {
					font-family: $ff1;
					font-weight: bold;
					color: $light;
					font-size: pte(20px, $fz);
					margin-bottom: 1rem;
				}
			}

			&__excerpt {
				display: none;
				p {
					font-family: $ff1;
					font-weight: 500;
					color: $light;
					font-size: 1rem;
					margin: 0;
				}
			}

			&__permalink {
				width: 100%;
				margin-top: 1rem;
				text-align: right;

				button {
					cursor: pointer;
				}
			}

		}
	}

	/* ----------------------------------------------------- */
	/* LIST */
	/* ----------------------------------------------------- */
	&-list {
		@include flex(flex, column, nowrap, flex-start, flex-start);
		padding: 0;

		.item {
			height: 100%;

			&__column {
				margin-bottom: .5rem;
			}

			&__inner {
				@include flex(flex, column, nowrap, space-between, stretch);
				height: 100%;
				padding: ptr(10px, $fz);
				border: 1px solid #D5D5D5;
				background-color: $light;
				box-shadow: 0 2px 14px 0 rgba(195,195,195,0.5);
			}

			&__title {
				h2 {
					color: $dark;
					font-family: $ff1;
					font-size: ptr(20px, $fz);
					font-weight: bold;
				}
			}

			&__image {
				position: relative;
				height: 50vh;
				overflow: hidden;

				img {
					display: block;
					@include objFit();
				}
			}

			&__categories-date {
	            @include flex(flex, row, wrap, flex-start, center);
	            position: absolute;
	            bottom: 0;
	            left: 1rem;
	            right: auto;
	            background-color: $col2;
	            padding: ptr(3px, $fz) ptr(5px, $fz);

	            &.stakeholder {
	            	background-color: $col4;
	            }
	            &.project {
	            	background-color: $col1;
	            }
	        }

	        &__categories,
	        &__date {
	            position: static;
	            bottom: auto;
	            font-family: $ff1;
	            font-size: ptr(14px, $fz);
	            color: $light;
	            text-transform: uppercase;
	        }

			&__media {
				video,
				iframe {
					width: 100%;
					height: auto;
				}
			}

			&__date {
				p {
					color: #A0A0A0;
					font-family: $ff1;
					font-size: ptr(12px, $fz);
					font-style: italic;
					font-weight: lighter;
					margin: 0;
				}
			}

			&__thematics,
			&__regions {
				margin-bottom: 1rem;

				p {
					color: $col1;

					img,
					i {
						display: inline-block;
						vertical-align: middle;
						font-size: ptr(18px, $fz);
						text-align: center;
						width: ptr(18px, $fz);
						margin-right: ptr(8px, $fz);
					}
				}
			}

			&__thematic {
				&:not(:last-child) {
					margin-right: .5rem;
				}
			}

			&__link {
				@include flex(flex, row, nowrap, space-between, flex-end);

				&.text-right {
					justify-content: flex-end;
				}
			}

			&__content {
				padding: 1rem;
				color: $dark;
			}

			&__permalink {
				button {
					cursor: pointer;
				}
			}

		}
	}

	/* ----------------------------------------------------- */
	/* MOTEUR DE RECHERCHE */
	/* ----------------------------------------------------- */
	&-search {
		// @include flex(flex, column, nowrap, center, stretch);
		padding: ptr(24px, $fz);
		background-color: #EFEFEF;
		margin-bottom: ptr(40px, $fz);

		// Filtres par tags
		.tags-container {
			@include flex(flex, row, wrap, flex-start, flex-start);
			margin: 0 0 0.8125em 0;

			.btn-tag-filter {
				line-height: ptr(44px, $fz);
				height: ptr(48px, $fz);
				padding-top: 0;
				padding-bottom: 0;
				display: block;
			}
		}

		// Filtres select
		select {
			height: 100%;
		}

		// Boutons de vue
		.toggle-map {
			width: 100%;
		}

		.map-mode {
			display: inline-block;
		}

		.list-mode {
			display: none;
		}

		body.map-toggled & {

			.map-mode {
				display: none;
			}

			.list-mode {
				display: inline-block;
			}

		}

	}

	/* ----------------------------------------------------- */
	/* PAGINATION */
	/* ----------------------------------------------------- */
	&-pagination {
		margin: 2rem 0;
		text-align: center;

		> ul {
			list-style: none;
			padding: 0;
			margin: 0;

			> li {
				display: inline-block;
				vertical-align: top;

				list-style: none;
				padding: 0;
				margin: ptr(4px, $fz);

				> a,
				> span {
					display: block;
					width: ptr(32px, $fz);
					height: ptr(32px, $fz);
					line-height: ptr(32px, $fz);
					text-align: center;
					border: 1px solid $col4;
					border-radius: 10em;
				}

				> a {
					background-color: $light;
					color: $col4;

					&.prev {
						border-color: transparent;
						margin-right: 1rem;
					}

					&.next {
						border-color: transparent;
						margin-left: 1rem;
					}

				}

				> span {
					background-color: $col4;
					color: $light;
				}
			}
		}
	}

	/* ----------------------------------------------------- */
	/* LIEN DE RETOUR */
	/* ----------------------------------------------------- */
	&-back {
		margin: 2rem 0 0 0;
	}

	/* ----------------------------------------------------- */
	/* TOOGLE DE LA VUE MAP \ CONTAINER FLEX POUR AFFICHAGE LIST + MAP */
	/* ----------------------------------------------------- */
	&__container {
		position: relative;

		.items-list {
			display: block;
		}

		.items-map {
			display: none;
		}

		body.map-toggled & {
			.items-list {
				display: none;
			}

			.items-map {
				display: block;
			}
		}
	}

	/* ----------------------------------------------------- */
	/* ZONE DE RAFRAICHISSEMENT AJAX */
	/* ----------------------------------------------------- */
	.ajax-items-refresh {
		position: relative;
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.items {
		&-list {
			.item {
				&__column {
					margin-bottom: 1rem;
				}

				&__image {
					height: 22vh;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.items {
		&-grid {
			@include flex(flex, row, wrap, flex-start, stretch);

			.item {
				// margin-bottom: 2rem;
				flex-basis: 50%;

				&__image {
					height: 34vh;
				}
			}
		}

		&-search {
			form {
				@include flex(flex, row, wrap, center, stretch);
			}

			.tags-container {
				flex-basis: 100%;
				@include flex(flex, row, wrap, center, stretch);
			}

			.select_container {
				margin: 0 1rem 0 0;

				select {
					margin: 0;
					width: auto;
					min-width: ptr(300px, $fz);
				}
			}

			.toggle-map {
				width: auto;
				@include flex(flex, row, nowrap, center, center);
			}

			// .btn {
			// }
		}


		&-list {
			.item {
				&__column {
					margin-bottom: 2rem;
				}
			}
		}

		&-slider {
			.item {
				&__image {
					height: 22vh;
				}
			}
		}

	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.items {
		margin: 2rem 0;

		&-grid {
			margin-top: ptr(58px, $fz_md);

			.item {
				padding: 1rem 0;

				&__excerpt {
					display: block;
				}
			}
		}

		&-list {
			@include flex(flex, row, wrap, flex-start, stretch);

			.item {
				padding: 1rem 0;
			}
		}


	}


}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.items {

		/* ----------------------------------------------------- */
		/* GRID */
		/* ----------------------------------------------------- */
		&-grid {
			@include flex(flex, row, wrap, flex-start, stretch);

			.item {
				flex-basis: calc(100% / 3);
			}
		}

		/* ----------------------------------------------------- */
		/* LIST */
		/* ----------------------------------------------------- */
		&-list {
			.item {
				flex-basis: calc(100% / 3);
			}
		}

		/* ----------------------------------------------------- */
		/* RECHERCHE */
		/* ----------------------------------------------------- */
		&-search {
			form {
				justify-content: center;
				align-items: center; 
			}

			.tags-container {
				flex-basis: auto;
				margin-right: 1rem;
				margin-bottom: 0;
			}
		}

	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

