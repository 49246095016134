.breadcrumbs {
    text-align: left;
    position: relative;
    padding: 1rem;
    margin: 0;
    color: #9C9C9C;

    .breadcrumb-nav {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            vertical-align: top;
            color: $col1;
            padding-right: 1.5em;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    > span {
        display: inline-block;
        white-space: nowrap;
        text-transform: none;
        color: $col1;
        font-family: $ff1;
        font-size: pte(16px, $fz);

        &.separator {
            margin: 0 0.5em;
            color: $col2;
        }
    }

    a {
        display: inline-block;
        color: $col2;
        font-family: $ff1;

        &:hover {
            color: $dark !important;
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILE : #{-xxs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {}

/* ----------------------------------------------------- */
/* MOBILE : #{-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {}

/* ----------------------------------------------------- */
/* TABLET : #{-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    .breadcrumbs {
        text-align: left;
    }
}

/* ----------------------------------------------------- */
/* TABLET PORTRAIT : #{-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    .breadcrumbs {
        // padding: 2rem 0 2rem 1rem;
    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {}

/* ----------------------------------------------------- */
/* DESKTOP : #{-xl} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {}
