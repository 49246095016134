/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SURCHARGE DES STYLES DE CAROUSEL */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
body {
	&.home {
		.site-home-slider {
			padding: 0;
			&_masque {
				@include flex(flex, row, nowrap, center, center);
				width: 100%;
				padding: 20px;
				&_container {
					@include flex(flex, column, nowrap, center, center);
					max-width: 400px;
					text-align: center;
					h1 {
						font-size: 2rem;
						font-weight: normal;
						margin-bottom: 20px;
						span {
							font-family: $ff2;
							font-weight: bold;
							display: block;
						}
					}
					p {
						font-size: 1rem;
						span {
							font-weight: bold;
						}
					}
				}
			}

			&_slider {
				img {
					height: 250px;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}

/* ----------------------------------------------------- */
/* SLICK CAROUSEL */
/* ----------------------------------------------------- */

.slick-slide {
	img {
		width: 100%;
	}
}

.slick-dots {
	display: none;

	li {
		vertical-align: middle;
		margin-right: ptr(6px, $fz);
		border-radius: 100em;
		overflow: hidden;
		z-index: 10;

		button {
			-webkit-appearance: none;
			width: ptr(15px, $fz);
			height: ptr(15px, $fz);
			background-color: $light;
			opacity: .5;
			overflow: hidden;

			&:before {
				display: none;
			}

			&:hover {
				opacity: 1;
			}
		}

		&.slick-active {
			button {
				opacity: 1;
			}
		}
	}
}


.slider-arrows {
	@include centerEl();
	z-index: 4;
}

.slick-arrow {
	@include flex(flex, row, nowrap, center, center);
	-webkit-appearance: none;
	width: pte(30px, $fz_lg);
	height: pte(30px, $fz_lg);
	line-height: 30px;

	border-radius: unset;
	border: 1px solid $col3;
	background-color: $light;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;

	font-size: 1em;
	text-align: center;
	z-index: 10;
	color: $col3;

	position: absolute;
	top: 130px;
	// transition: $trans;

	&:before {
		display: none;
	}

	&:hover {
		color: $light;
		background-color: darken($col3, 10%);
	}
}

.slick-prev {
	left: 0px;
	//background-image: url($imagePath + 'slider-left.svg');

	&:hover {
		// background-repeat: no-repeat;
		// background-position: center;
		// background-image: url($imagePath + 'slider-left-hover.svg');
	}
}

.slick-next {
	right: 0px;
}


// .slick-slider {
// 	.slick-track,
// 	.slick-list {
// 		width: 100% !important;
// 	}
// }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {



}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.slick-arrow {
		&.slick-prev {
			left: -50px;
		}

		&.slick-next {
			right: -50px
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.slider-arrows {
		top: calc(50% + #{$hdr_h} / 2);
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
	body {
		&.home {
			.site-home-slider {
				padding: 0;
				height: 600px;
				&_masque {
					@include flex(flex, row, nowrap, flex-end, center);
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
					background: url('../images/masque_slider.png');
					width: 100%;
					height: 600px;
					background-position-x: right;
					background-repeat: no-repeat;
					&_container {
						@include flex(flex, column, nowrap, center, center);
						max-width: 400px;
						margin-right: 8%;
						text-align: center;
						h1 {
							font-size: 2rem;
							font-weight: normal;
							margin-bottom: 20px;
							span {
								font-family: $ff2;
								font-weight: bold;
								display: block;
							}
						}
						p {
							font-size: 1rem;
							span {
								font-weight: bold;
							}
						}
					}
					&_img {
						img {
							height: 600px;
						}
					}
				}
				&_slider {
					margin: 0;
					.slider-element {
						img {
							height: 600px;
							width: auto;
						}
					}
				}
				.slick-dots {
					display: block;
					text-align: center;
					bottom: pte(40px, $fz);
					padding: .5rem;
					width: 40%;
				}
			}
		}
	}
}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
	body {
		&.home {
			.site-home-slider {
				overflow: hidden;
				&_slider {
					max-width: 1140px;
					.slider-element {
						height: 600px;
						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
				}
			}
		}
	}
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {



}

