/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PAGINATION */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.pagination {
  ul, li {
    list-style: none;
  }

  padding: 0;
  margin: 30px 0;
  display: inline-block;
  width: 100%;
  text-align: center;

  > li {
    text-align: center;
    display: inline-table;
    list-style: none;
    padding: 0;
    margin: 5px;

    a {
      padding: 10px 16px;
      display: block;
      color: $col1;
      font-weight: 700;
      font-size: 1.7em;

      &:hover,
      &.page_active {
        color: $col2;
      }
    }
  }

  ul {
    @include flex(flex, row, nowrap, center, center);
    li {
      &.item-pg {
        color: $col1;
        margin: 0 3px;
        a {
          @include flex(flex, row, nowrap, center, center);
          padding: 4px 6px;
          &.disabled {
            font-weight: bold;
          }
        }
      }
      &.next, &.prev {
        background-color: $col1;
        border: 1px solid $col1;
        a {
          color: $light;
          font-size: 1rem;
        }
      }
      &.first, &.last {
        background-color: $light;
        border: 1px solid $col1;
        a {
          color: $col1;
          padding: 4px 2px;
          font-size: 1rem;
        }
      }
    }
  }

}

