/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.site-footer {
    position: relative;
    background-color: $ftr_bg;
    padding: 2rem 1rem 1rem 1rem;

    /* ----------------------------------------------------- */
    /* BACK TO TOP */
    /* ----------------------------------------------------- */
    #buttonTop.show {
        opacity: 1;
        visibility: visible;
    }
    #buttonTop {
        @include flex(flex, column, nowrap, center, center);
        background-color: $col1;
        width: 45px;
        height: 45px;
        text-align: center;
        position: fixed;
        bottom: 80px;
        right: 10px;
        transition: background-color .3s, opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
        color: #fff;
        cursor: pointer;
        transition: all 0.4s ease-out;
        span{
            transform: rotate(180deg);
        }
    }

    /* ----------------------------------------------------- */
    /* TITRE DES COLONNES */
    /* ----------------------------------------------------- */
    &__title {
        position: relative;
        font-family: $ff1;
        font-size: pte(16px, $fz);
        font-weight: bold;
        text-align: left;
        color: $col5;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: ptr(40px, $fz);
            height: 1px;
            background-color: $light;
        }
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* "MODULES" */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* LOGO */
    /* ----------------------------------------------------- */
    .logo {
        justify-content: center;
        align-items: center;
    }

    &__logo {
        text-align: center;

        img {
            width: 100%;
            max-width: ptr(292px, $fz);
        }
    }

    /* ----------------------------------------------------- */
    /* Adresse */
    /* ----------------------------------------------------- */
    &__address {
        margin: 2rem 0;

        span[itemprop="address"] {
            display: inline-block;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            color: $light;
        }

        a {
            -webkit-transition: $trans;
            -moz-transition: $trans;
            -ms-transition: $trans;
            -o-transition: $trans;
            transition: $trans;
            color: $light;
            display: block;
            margin-bottom: .25rem;
            
            i {
                font-size: 1rem;
                vertical-align: middle;
                margin-right: 1rem;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* Reseaux sociaux */
    /* ----------------------------------------------------- */
    &__networks {
        margin: 2rem 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: left;

            li {
                list-style: none;
                display: inline-block;
                vertical-align: top;
                font-weight: normal;

                a {
                    color: $light;
                    @include flex(flex, row, nowrap, center, center);
                    width: ptr(40px, $fz);
                    height: ptr(40px, $fz);
                    border-radius: 100em;
                    border: 1px solid $light;

                    i {
                        font-size: 1rem;
                    }
                }

                &:not(:last-child) {
                    margin-right: .5rem;
                }

            }
        }

    }

    /* ----------------------------------------------------- */
    /* Newsletter */
    /* ----------------------------------------------------- */
    &__newsletter {
        .mc4wp-form-fields {
            position: relative;

            input[type="email"],
            input[type="text"] {
                position: relative;
                z-index: 1;
                width: 100%;
                height: pte(30px, $fz);
                border-bottom: 1px solid $light;
                padding-bottom: 0.5em;
                font-family: $ff1;
                font-size: pte(15px, $fz);
                font-weight: normal;
                font-style: italic;
                color: $light;
                background-color: transparent;
                border-radius: 0;
                text-transform: none;

                &::-webkit-input-placeholder {
                    color: $light;
                    font-style: normal;
                    font-weight: normal;
                }

                &:-moz-placeholder {
                    color: $light;
                    font-style: normal;
                    font-weight: normal;
                }

                &:-ms-input-placeholder {
                    color: $light;
                    font-style: normal;
                    font-weight: normal;
                }
            }

            .submit {
                position: absolute;
                top: 50%;
                right: 0;
                -webkit-transform: translate(0, -50%);
                -moz-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                -o-transform: translate(0, -50%);
                transform: translate(0, -50%);
                color: $light;
                background-color: transparent;
                z-index: 3;

                input[type="submit"] {
                    opacity: 0;
                }

                > i {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    font-size: 1.5em;
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    z-index: 2;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* Mentions / Koredge */
    /* ----------------------------------------------------- */
    &__mentions {
        @include flex(flex, column, wrap, center, center);

        p {
            font-family: $ff1;
            font-weight: normal;
            font-size: 1rem;
            color: $light;
            margin: 0 0 20px;
            text-align: center;

            &.site-footer__mentions_column {
                @include flex(flex, column, wrap, center, center);
            }

            i {
                font-size: 1.2em;
                vertical-align: middle;
                margin-right: 0.25em;
            }

            a {
                color: rgba($light,0.8);
                padding-right: 20px;
                &:hover {
                    color: $light;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* Menu */
    /* ----------------------------------------------------- */
    &__menu {
        margin: 2rem 0;

        .nav {
            display: block;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: left;

            .menu_item {
                list-style: none;
                font-family: $ff1;
                color: $light;
                font-size: 1em;
                font-weight: normal;
                font-size: 1rem;
                padding: 0 0 .5rem 0;

                a {
                    color: inherit;
                }

                &.menu_item_current {
                    a {
                        color: $col4;
                        font-weight: 600;
                    }
                }
            }
        }

        .btn {
            margin-top: 1rem;
        }
    }

    /* ----------------------------------------------------- */
    /* Discover */
    /* ----------------------------------------------------- */
    &__discover {
        margin: 2rem 0;

        .nav {
            display: block;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: left;
            

            .menu_item {
                list-style: none;
                font-family: $ff1;
                color: $light;
                font-size: 1em;
                font-weight: normal;
                font-size: 1rem;
                padding: .5rem 0;

                a {
                    color: inherit;
                }

                &.menu_item_current {
                    a {
                        color: $col5;
                        font-weight: 600;
                    }
                }
            }
        }

        .btn {
            margin-top: 1rem;
        }
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* Agencement */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* Haut */
    /* ----------------------------------------------------- */
    &__top {}

    /* ----------------------------------------------------- */
    /* Bottom */
    /* ----------------------------------------------------- */
    &__bottom {
        .row.flex {
            align-items: center;
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* Scroll to Top */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.scrolltotop {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: $trans;
    -moz-transition: $trans;
    -ms-transition: $trans;
    -o-transition: $trans;
    transition: $trans;
    left: 0;
    bottom: 0;
    z-index: 6789;

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    button {
        -webkit-appearance: none;
        width: $hdr_h;
        height: $hdr_h;
        cursor: pointer;
        padding: 0;

        i {
            transform: rotate(-90deg);
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MailChimp Messages */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.mc4wp-alert {
    p {
        font-family: $ff1;
        font-weight: normal;
        font-size: 1em;
        color: $light;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &.mc4wp-error {
        p {
            color: #FF0000;
        }
    }
}

/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* RESPONSIVE                                                                                                 */
/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    .site-footer {
        &__mentions {
            width: 100%;

            p {
                text-align: center;
            }
        }
    }
}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    .site-footer {
        padding-bottom: 2rem;
    }

}

/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    .site-footer {
        margin: 0;

        &__mentions {
            margin-bottom: 0;
            @include flex(flex, row, wrap, space-between, center);
            p {
                &.site-footer__mentions_column {
                    @include flex(flex, row, wrap, center, center);
                }
                margin: 0;
            }
        }
    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
    .site-footer {

        padding: 2rem 1rem ptr(60px, $fz) 1rem;
        #buttonTop {
            bottom: 150px;
            right: 70px;
        }
    }
}
