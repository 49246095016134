/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PAGE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
section {
    &.bg-col2 {
        background-color: $col2;
        h2 {
            @include flex(flex, column, nowrap, center, center);
            padding: 0 pte(40px, $fz);
            width: 100%;
            height: 100px;
            color: $light;
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            font-weight: normal;

            span {
                display: block;
                font-weight: bold;
                font-family: $ff2;
                font-size: 1.9rem;
            }
        }
    }
    .site-page-contenu {
        padding: 20px;
        &.contenu_flex {
            @include flex(flex, column, nowrap, space-between, flex-start);
            &_left,&_right{
                width: 100%;
                @include flex(flex, column, nowrap, center, center);
            }
            svg {
                max-width: 350px;
            }
            .site-page-contenu_slider {
                width: 100%;
                height: 300px;
                img {
                    height: 300px;
                    object-fit: cover;
                    object-position: center;
                }
                .slick-dots {
                    bottom: -25px;
                    li {
                        button {
                            background-color: $col1;
                        }
                    }
                }
            }
        }
        h1 {
            text-align: center;
            font-size: 2rem;
            font-weight: normal;
            line-height: 40px;
            color: $col2;
            margin: pte(20px, $fz) auto;
            span {
                font-family: $ff2;
                font-size: 2.2rem;
                display: block;
            }
        }
        h2 {
            color: $col2;
            font-size: 1.2rem;
            line-height: 2rem;
            font-weight: normal;
            span {
                display: block;
                font-weight: bold;
                font-family: $ff2;
                font-size: 1.5rem;
            }
        }
        h3 {
            color: $col2;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: normal;
            span {
                display: block;
                font-weight: bold;
                font-family: $ff2;
                font-size: 1.2rem;
                letter-spacing: 0.2rem;
            }
        }
        h4 {
            color: $col2;
            font-size: 1rem;
            line-height: 1rem;
            font-style: italic;
        }
        a {
            &.link-icon {
                @include flex(flex, row, nowrap, center, center);
                border-radius: 50%;
                border: 1px solid $col1-facebook;
                background-color: $col1-facebook;
                color: $light;
                text-align: center;
                font-size: ptr(12px, $fz_xs);
                width: calc(40px - 1rem);
                height: calc(40px - 1rem);
                font-weight: normal;
            }
        }
        form {
            max-width: 350px;
            margin: 10px 0;
            label, select {
                margin: 10px 0;
            }
            &.relative {
                position: relative;
                select {
                    cursor: pointer;
                }
                i {
                    position: absolute;
                    right: 15px;
                    top: 48px;
                    pointer-events: none;
                }
            }
        }
        &_gallery {
            @include flex(flex, row, wrap, center, center);
            img {
                width: 100%;
                max-width: 265px;
                opacity: 0.5;
                transition: opacity 1s;
                &:hover,&.light {
                    opacity: 1;
                }
            }
        }
        .site-page-artisans_item {
            @include flex(flex, column, nowrap, center, center);
            border-bottom: 1px solid $col2;
            padding: pte(20px, $fz) 0;
            &_left,&_right {
                width: 100%;
            }
            p {
                color: $col2;
                margin: 0;
                font-size: 1rem;
            }
            a {
                &.link-icon {
                    font-weight: normal;
                    font-size: 0.75rem;
                }
                font-weight: bold;
                font-size: 1rem;
            }
            &_card {
                @include flex(flex, column, nowrap, center, center);
                &_left,&_right {
                    width: 100%;
                    padding: 0  0 pte(20px, $fz) 0;
                }
                &_left {
                    @include flex(flex, column, nowrap, center, center);
                    &_top,&_bottom {
                        width: 100%;
                        padding: pte(10px, $fz) 0;
                    }
                    &_top {
                        @include flex(flex, row, nowrap, space-between, center);
                    }
                    img {
                        border: 10px solid $col2;
                        width: 100%;
                    }
                }
                &_right{
                    @include flex(flex, column, nowrap, flex-start, flex-start);
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
    .site-contenu-avis {
        padding: pte(20px, $fz);
        h3 {
            color: $col2;
            font-size: 1.5rem;
            display: block;
            font-weight: bold;
            font-family: $ff2;
            margin-bottom: pte(20px, $fz);
            letter-spacing: 0.2rem;
        }
        .avis-item {
            padding: pte(20px, $fz) pte(10px, $fz);
            border-bottom: 1px solid $col2;
            span {
                display: block;
                font-size: 1rem;
            }
        }
    }
}

/* ----------------------------------------------------- */
/* ALERTE MESSAGE */
/* ----------------------------------------------------- */
.alert_message {
    position: fixed;
    top: 350px;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.56);
    padding: 10px;
    z-index: 9999999;
    color: #FFFFFF;
    text-align: center;
    font-size: 1rem;
    &.error {
        background: #D13239;
    }
    &.success {
        background: $col2;
    }
    p{
        margin:0;
    }
}


/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {
}

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
    section {
        .site-page-contenu {
            &.contenu_flex {
                .site-page-contenu_slider {
                    height: 350px;
                    img {
                        height: 350px;
                        object-position: bottom;
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    section {
        .site-page-contenu {
            &.contenu_flex {
                @include flex(flex, row, nowrap, space-between, flex-start);
                width: 100%;
                &_left,&_right{
                    width: 50%;
                }
                .site-page-contenu_slider {
                    margin: pte(120px, $fz) auto;
                }
            }
            &_gallery {
                @include flex(flex, row, nowrap, space-between, center);
                img {
                    width: 25%;
                    max-width: 265px;
                }
            }
            .site-page-artisans_item {
                @include flex(flex, row, nowrap, space-around, stretch);
                &_left,&_right {
                    width: 45%;
                }
                &_right {
                    padding-top: pte(60px, $fz);
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    main {
        &.background {
            background-image: url("../images/taches_vache_haut_gauche.png"), url("../images/taches_vache_haut_droite.png"), url("../images/taches_vache_bas_droite.png");
            background-repeat: no-repeat;
            background-size: auto;
            background-position: top left, top right, bottom right;
        }
    }
    section {
        .site-page-contenu {
            &.contenu_flex {
                svg {
                    max-width: 500px;
                }
                .site-page-contenu_slider {
                    height: 500px;
                    img {
                        height: 500px;
                        object-position: center;
                    }
                }
            }
            .site-page-artisans_item {
                @include flex(flex, row, nowrap, space-around, stretch);
                &_left {
                    width: 70%;
                }
                &_right {
                    width: 30%;
                    padding-top: pte(80px, $fz);
                }
                &_card {
                    @include flex(flex, row, nowrap, space-between, stretch);
                    &_right, &_left {
                        width: 49%;
                    }
                    &_right {
                        padding-top: pte(80px, $fz);
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
}

/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {}
