.btn {
    display: inline-block;
    position: relative;
    padding: pte(20px, $fz) pte(64px, $fz);
    transition: $trans;
 
    -webkit-appearance: none;
    border: none;
    border-radius: $bdrs;
    overflow: hidden;

    font-family: $ff1;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    text-transform: uppercase;

    

    & + & {
        margin: 0 0 0 .5rem;
    }

    // &:after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     z-index: 0;
    //     top: 50%;
    //     left: 50%;
    //     right: auto;
    //     width: 200%;
    //     height: 200%;
    //     border-radius: 100em;
    //     transition: $cubic2;
    //     background-color: rgba($dark, 0.2);
    //     -webkit-transform: translate(-50%, -50%) scale(0);
    //     -moz-transform: translate(-50%, -50%) scale(0);
    //     -ms-transform: translate(-50%, -50%) scale(0);
    //     -o-transform: translate(-50%, -50%) scale(0);
    //     transform: translate(-50%, -50%) scale(0);
        
    // }

    > i {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.0em;
        position: relative;
        z-index: 2;
        margin-right: 0rem;

        &.icon-before {
            margin-right: 0.5rem;
        }

        &.icon-after {
            margin-left: 0.5rem;
        }
    }

    span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 3;
    }

    &:hover {
        // &:after {
        //     -webkit-transform: translate(-50%, -50%) scale(1);
        //     -moz-transform: translate(-50%, -50%) scale(1);
        //     -ms-transform: translate(-50%, -50%) scale(1);
        //     -o-transform: translate(-50%, -50%) scale(1);
        //     transform: translate(-50%, -50%) scale(1);
        // }
        cursor: pointer;
    }

    /* ----------------------------------------------------- */
    /* FULL LARGEUR */
    /* ----------------------------------------------------- */
    &.full-width {
        width: 100%;
    }

    /* ----------------------------------------------------- */
    /* BOUTONS RONDS */
    /* ----------------------------------------------------- */
    &.btn-round {
        border-radius: 100em;
        padding: .8rem;
        margin: 0;
    }

    /* ----------------------------------------------------- */
    /* DEFAULT */
    /* ----------------------------------------------------- */
    &.btn-default {
        color: $light;
        border: none;
        background-color: $col1;

        &:hover {
            background-color: $col2;
        }
    }

    /* ----------------------------------------------------- */
    /* DEFAULT OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-default-outlined {
        color: $col1;
        border: 2px solid $col1;
        background-color: $light;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: bold;
        

        &:after {
            background-color: $col1;
        }

        &:hover,
        &.active {
            color: $light;
            border-color: $col1;
            background-color: $col1;
        }
    }

    /* ----------------------------------------------------- */
    /* DARK */
    /* ----------------------------------------------------- */
    &.btn-dark {
        color: $light;
        border: none;
        background-color: $dark;

        &:hover {
            color: $light;
            background-color: $dark;
        }
    }

    /* ----------------------------------------------------- */
    /* DARK OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-dark-outlined {
        color: $dark;
        border: 1px solid $dark;
        background-color: transparent;

        &:hover {
            color: $col2;
            border-color: $col2;
            background-color: transparent;
        }
    }

    /* ----------------------------------------------------- */
    /* LIGHT */
    /* ----------------------------------------------------- */
    &.btn-light {
        color: $coltext;
        border: none;
        background-color: $light;

        &:hover {
            color: $light;
            background-color: $col4;
        }
    }

    /* ----------------------------------------------------- */
    /* LIGHT OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-light-outlined {
        color: $light;
        border: 1px solid $light;
        background-color: transparent;

        &:hover {
            color: $col2;
            border-color: $col2;
            background-color: transparent;
        }
    }

    /* ----------------------------------------------------- */
    /* PRIMARY */
    /* ----------------------------------------------------- */
    &.btn-primary {
        color: $light;
        border: none;
        background-color: $col3;
        outline: none;

        &:after {
            background-color: $col3;
        }

        &:hover {
            color: $light;
            background-color: darken($col3, 10%);

            // &:before
            //  max-width: 100%
     
        }
    }

    /* ----------------------------------------------------- */
    /* PRIMARY OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-primary-outlined {
        color: $col2;
        border: 1px solid $col2;
        background-color: transparent;

        &:after {
            background-color: $col2;
        }

        &:hover {
            color: $light;
            border-color: $col2;
            background-color: transparent;
        }
    }

    /* ----------------------------------------------------- */
    /* SECONDARY */
    /* ----------------------------------------------------- */
    &.btn-secondary {
        color: $light;
        border: none;
        background-color: $col5;

        &:hover {
            color: $light;
            background-color: darken($col5, 10%);
        }
    }

    /* ----------------------------------------------------- */
    /* ACTORS */
    /* ----------------------------------------------------- */
    &.btn-actors,
    &.btn-stakeholder {
        color: $light;
        border: none;
        background-color: $col4;

        &:hover {
            color: $light;
            background-color: $col4-dark;
        }
    }

    /* ----------------------------------------------------- */
    /* PROJECTS */
    /* ----------------------------------------------------- */
    &.btn-projects,
    &.btn-project {
        color: $light;
        border: none;
        background-color: $col1;

        &:hover {
            color: $light;
            background-color: $col1-dark;
        }
    }

    /* ----------------------------------------------------- */
    /* ENTOURE SECONDAIRE */
    /* ----------------------------------------------------- */
    &.btn-secondary-outlined {
        color: $col3;
        font-weight: normal;
        background-color: transparent;
        border: 1px solid $col3;

        &:hover {
            color: darken($col3, 10%);
            background-color: transparent;
            border-color: darken($col3, 10%);
        }
    }

    /* ----------------------------------------------------- */
    /* SOULIGNE */
    /* ----------------------------------------------------- */
    &.btn-underlined {
        color: $col1;
        border-bottom: 5px solid $col1;

        &:hover {
            color: darken($col1, 10%);
            background-color: transparent;
            border-bottom: 3px solid darken($col1, 10%);
        }
    }

    /* ----------------------------------------------------- */
    /* ANIMATIONS */
    /* ----------------------------------------------------- */
    &.btn-balance {
        &:hover {
            > i {
                animation: balance 1s infinite ease-in-out;
            }
        }
    }

    &.btn-balance-vertical {
        &:hover {
            > i {
                animation: ressortv 1s infinite both ease-in-out;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* BTN LINK */
    /* ----------------------------------------------------- */
    &.btn-link {
        text-transform: none;
        color: $dark;
        padding: 0;
        background-color: transparent;

        &:after {
            display: none;
        }

        &:hover {
            color: $dark;
        }
    }
}


/* ----------------------------------------------------- */
/* BOUTON DOWNLOAD */
/* ----------------------------------------------------- */
a.download {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    position: relative;
    height: ptr(109px, $fz);
    border-top: 2px solid $col1;
    border-bottom: 2px solid $col1;
    max-width: calc(100% - 99px - 99px);

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        display: block;
        background-image: url($imagePath + 'download_before.png');
        width: ptr(99px, $fz);
        height: ptr(109px, $fz);
        top: -2px;
        left: ptr(-99px, $fz);
    }

    &:after {
        content: "";
        position: absolute;
        display: block;
        z-index: 1;
        background-image: url($imagePath + 'download_after.png');
        width: ptr(99px, $fz);
        height: ptr(109px, $fz);
        top: -2px;
        right: ptr(-99px, $fz);
    }

    // html.firefox & {
    //     border-image-source: url($imagePath + 'clip_download.svg');
    //     border-image-slice: 28 30;
    //     border-image-width: 30px;
    //     padding: 0 ptr(60px, $fz);
    //     line-height: ptr(60px, $fz);
    //     position: relative;

    //     i {
    //         position: absolute;
    //         left: -4px;
    //     }
    // }
    
    i {
        display: none;
    }

    span {
        position: relative;
        z-index: 3;
        color: $col2;

        &.col1 {
            color: $col1;
            font-weight: bold;
            display: inline-block;
            width: 100%;
            font-size: 1.25rem;
        }
    }
}

button {
    -webkit-appearance: none;
    cursor: pointer;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    .btn {
        padding: 1rem 2rem;
    }

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    

}

